import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
const MoreInformationModal = ({ close , options,changeSlider }) => {
  const [slider, setSlider] = useState(100);
  const [htmlMI,setHtmlMI] = useState({__html:''});
  const updateSlider = () => {
    changeSlider(slider);
    }
  useEffect(()=>{
    if(options.footnotes){
      setHtmlMI({__html:options.footnotes});
    }
    if(options.slider) {
      setSlider(options.slider);
    }
  },[options])
  return (
    <div className="menu-panel menu-info">
      <button type="button" className="btn btn-link btn-close" onClick={() => close(false)}>
        Close
        <img src="assets/close_v2.svg" alt="" className="ml-1"  style={{height: '17px'}}/>
      </button>
      <div className="container">
        <h6><strong>More information</strong></h6>
        {/* <div dangerouslySetInnerHTML={htmlMI}></div> */}
        <p><label class="cubo-1"></label>Data not shown<span>*</span></p>
        <p><span>*</span>Data are not shown to protect privacy. See <a href="https://hepvu.org/about/data-methods/">Data Methods</a>.</p>
        <p className="information p-0" style={{margin:'25px 0px 0px 0px'}}>
          <strong>Layer Opacity:
            <span className="text-primary-dark">&nbsp;{slider}%</span>
          </strong>
          <input id='slider' type='range' min='0' max='100' step='0' value={slider} onMouseLeave={ updateSlider} onTouchEnd={ updateSlider} onChange={(e) => setSlider(e.target.value)} />
        </p>
      </div>
    </div>
  )
}



const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSlider: (value) => {
      dispatch({type:'CHANGE_SLIDER', value})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreInformationModal)
