import axios from 'axios';

const PrintDataService = () => {

  return {
    getImagePython: (body) => {
      return axios.post("https://map.hepvu.org/python/print", body)
    }
  }
}

export default PrintDataService;