import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { getStatePopUpHTML, getCountyPopUpHTML, getCityPopUpHTML, getCountyComparerPopUpHTML, getStateComparerPopUpHTML, getCityComparerPopUpHTML } from './services/popupService';

const getLo = (popupRef, dataX, dataY) => {
  let popupOffsetWidth = 0;
  let popupOffsetHeight = 0;
  if (popupRef && popupRef.current && popupRef.current.offsetWidth) {
    popupOffsetWidth = popupRef.current.offsetWidth;
    popupOffsetHeight = popupRef.current.offsetHeight;
  }

  let leftOffset = parseInt(dataX - 10);
  let topOffset = parseInt(dataY  - 10);
  let toolTipPlacement = 'top';

  if (popupOffsetWidth > 150) {
    leftOffset -= parseInt(popupOffsetWidth / 2);
    topOffset -= popupOffsetHeight;
  }

  if (topOffset < 0) {
    topOffset = dataY +10;
    toolTipPlacement = 'bottom'
  }

  leftOffset = 2 * parseInt(leftOffset / 2);
  topOffset = 2 * parseInt(topOffset / 2);

  const tipLo = leftOffset + parseInt(popupOffsetWidth / 2);
  const tipTo = topOffset + (toolTipPlacement === 'top' ? popupOffsetHeight : -10)

  return [leftOffset, topOffset, toolTipPlacement, tipLo, tipTo]
}

const PopupStatic = ({ data, hoveredMap, setHoveredMap, keep, insertPopup, close, options, popupsData, stateValues, countyValues, cityValues, stateCodes, order, stateComparison, countyComparison, cityComparison, comparisonOptions } ) => {
  const popupRef = useRef(null);
  const [popupHTML, setPopupHTML] = useState('');
  const [popupHTML2, setPopupHTML2] = useState('');
  const [foo, setFoo] = useState(0);

  const id = data.object.properties.cartodb_id;

  const onMouseHover = (flag) => {
    setHoveredMap({
      ...hoveredMap,
      [id]: flag
    })
  }

  useEffect(() => {
    return () => {
      let tmp = {...hoveredMap}
      delete tmp[id];
      setHoveredMap({ ...tmp })
    }
  }, [])

  useEffect(() => {
    if (options.maptype == 'state') {
      let state = data ? data.object.properties.name : '';
      if(order == 'first'){
        getStatePopUpHTML(state, popupsData, options, stateValues, setPopupHTML);
      } else {
        getStateComparerPopUpHTML(state, popupsData, options, stateComparison, setPopupHTML2, comparisonOptions);
      }
      
    } else if (options.maptype === 'county') {
      const geo_id2 = data.object.properties.geo_id2;
      let county = data.object.properties.name + ' County';
      const state = data.object.properties.state;
      if(order == 'first') {
        getCountyPopUpHTML(geo_id2, county, state, popupsData, options, countyValues, stateCodes, setPopupHTML);
      } else {
        getCountyComparerPopUpHTML(geo_id2, county, state, popupsData, options, countyComparison, stateCodes, setPopupHTML2, comparisonOptions);
      }
      
    } else {
      if (data.layer.id != 'centroids') {
        if(order == 'first') {
          getCityPopUpHTML(data.object.properties, popupsData, options, cityValues, setPopupHTML);
        } else {
          getCityComparerPopUpHTML(data.object.properties, popupsData, options, cityComparison, setPopupHTML2, comparisonOptions);
        }
        
      } else {
        setPopupHTML(`<div style="width:190px;display:inline-block;vertical-align:middle; margin-bottom:-10px; text-align: center;"><div class="popup-text"> <b> <h6> ${data.object.properties.city}, ${data.object.properties.state_ab} </h6> </b></div></div>`)
      }
    }
    if( data.layer.id.startsWith('cluster') ) {
      setPopupHTML(data.object.properties.popupTitle);
      setPopupHTML2(''); // TODO: is not clear what to do when overlays is activated and popup comparer is activated
    }
  }, [data.object]);

  const fixPopup = () => {
    if (insertPopup) {
      insertPopup(data);
    }
  }

  const [lo, to, toolTipPlacement, tipLo, tipTo] = useMemo(
    () => getLo(popupRef, data.x, data.y)
  , [popupRef, data.x, data.y, insertPopup, foo]);

  useEffect(() => {
    setFoo(Math.random());
    const interval = setInterval(() => {
      setFoo(Math.random())
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const popupStyle = {
    position: 'absolute',
    left: lo,
    top: to,
    maxWidth: '242px',
    fontSize: '69%'
  }

  const popupTipStyle = {
    position: 'absolute',
    left: tipLo,
    top: tipTo,
    border: '10px solid transparent',
    zIndex: 1,
    alignSelf: 'center',
    borderTop: 'none',
    borderBottomColor: '#fff',
    boxSizing: 'block',
    transform: toolTipPlacement === 'top' ? 'rotate(180deg)' : ''
  }

  if ((!popupHTML && order=='first') || (!popupHTML2 && order=='second')) {
    return null;
  }

  return (
    <Fragment>

      <div ref={popupRef} onMouseOver={() => onMouseHover(true)} onMouseLeave={() => onMouseHover(false)} className="mapboxgl-popup-content"
        style={popupStyle}>
        <button className="mapboxgl-popup-close-button" type="button" aria-label="Close popup"
          onClick={() => close(data)}>
          ×
      </button>
        {
          keep &&
          <button className={'pin ' + (!insertPopup ? 'pin-red' : 'active')} type="button" name="button" placement="right"
            onClick={fixPopup}>
            <i className="mdi mdi-pin"></i>
          </button>
        }
        {
          (popupHTML && order=='first') &&
          <div dangerouslySetInnerHTML={{ __html: popupHTML }}></div>
        }
        {
          (popupHTML2 && order=='second') &&
          <div dangerouslySetInnerHTML={{ __html: popupHTML2 }}></div>
        }
      </div>
      <div id="popup-tip" style={popupTipStyle}>
      </div>
    </Fragment>
  )
}

export default PopupStatic;