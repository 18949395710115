import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

const YearDropdown = ({options, changeYear}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [items, setItems] = useState([
    '2018',
    '2017',
    '2016',
    '2015',
  ]);
  const [yearSelected, setYearSelected] = useState(2018);
  const getYears = (years) => {
    const datatype = options.datatype;
    const maptype = options.maptype;
    const ans = [];
    for (const year of years) {
      // if (datatype === 'PrEP' || datatype === 'PNR') {
        if( datatype === 'death' && maptype === 'county') {
          if (+year >= 2005 && +year < 2018) {
            ans.push(year);
          }
        } else if( datatype === 'opioidprescriptionrate' && maptype === 'county') {
          if (+year >= 2020 && +year <= 2020) {
            ans.push(year);
          }
        } else if (datatype === 'narcoticoverdosemortality' && maptype === 'county') {
          if (+year >= 2014 && +year < 2021) {
            ans.push(year);
          }
        } else if (datatype === 'narcoticoverdosemortality') {
          if (+year >= 2014 && +year <= 2020) {
            ans.push(year);
          }
        } else if (datatype === 'Injdeathspct') {
          if (+year >= 2000  && +year <= 2020) {
            ans.push(year);
          }
        } else {
          if (+year > 2012 && +year < 2018) {
            ans.push(year);
          }
        }
        
      // } else {
      //   if (+year <= 2016) {
      //     ans.push(year);
      //   }  else {
      //     // WARNING
      //     // BRUTE FORCE CONDITION FOR DIFERENT YEARS THAN PRERP 
      //     if ((datatype === 'newdx') && (maptype === 'county' || maptype === 'state') && +year<=2018) {
      //       ans.push(year);
      //     }
      //     if ( datatype === 'testing' ){
      //       ans.push(year);
      //     }
      //   }
      // }
    }
    return ans;
  }
  const changeYearSelected = (year) => {
    setYearSelected(year);
    changeYear(year);
  }
  useEffect(()=>{
    setItems( getYears(options.year.year));
    setYearSelected(options.year.selected);
  },[options]);
  return (
    <Dropdown className="btn-group ml-2" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle caret>
        {yearSelected}
        <i className="mdi mdi-menu-down icon-lg "></i>
      </DropdownToggle>
      <DropdownMenu>
        {
          items.map((label) => (
            <DropdownItem key={ 'di-' + label } onClick={() => changeYearSelected(label)}>
              {label}
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  )
}

const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeYear: (value) => {
      dispatch({ type: 'CHANGE_SELECTED', key:'year', value });
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YearDropdown)