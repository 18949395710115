import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux'
import { Button, ButtonGroup } from 'reactstrap';
import OptionComparison from './OptionComparison';

const DataComparison = ({options,dcoptions,updateDC, changeDCOption, changeSelectedDC, close}) => {

  const [availableOpts, setAvailableOpts] = useState({
    'Poverty': '',
    'High School Education': '',
    'Median Household Income': '',
    'Income Inequality':'',
    'People Without Health Insurance': '',
    'Unemployment':'',
    'Severe Housing Cost Burden':'',
    'Opioid Prescription':'',
    'Pain Reliever Misuse': '',
    'Overdose Mortality':'',
    'Fibrosis Restrictions':'',
    'Sobriety Restrictions':'',
    'Prescriber Restrictions':'',
    'Prior Authorization':'',
    'Retreatment Restrictions': ''
  });
  // useEffect(()=>{
  //   console.log("AVLOPTS",availableOpts);
  // },[availableOpts]);
  useEffect(() => {
    let opts = availableOptions('datacomparison');
    let newAvOpt = {...availableOpts}
    if(opts.length > 0){
      for(let key in newAvOpt) {
        let isOpt = opts.filter(({label})=> label.toUpperCase() === key.toUpperCase());
        if(isOpt.length > 0) {
          isOpt.map(({value,label})=>{
            newAvOpt[key] = {
              value: value,
              label: label
            }
          });
        } else {
          newAvOpt[key] = '';
        }
      }
      setAvailableOpts(newAvOpt);
    }
  },[options]);
  const updateFilters = (type, value) => {
    this.model[type] = value.split('/')[1];
    this.options[type]['selected'] = value.split('/')[0];
    return true;
  }
  const  availableOptions = (param) => {
    if (options) {
      let filter = [];
      options[param]['labels'].forEach((ele, idx) => {
        if (options[param]['hidden']) {
          if ( options[param][param][idx].includes('sobriety') || options[param][param][idx].includes('narcoticoverdosemort') || options[param][param][idx].includes('fibrosis') || options[param][param][idx].includes('prescriber') || options[param][param][idx].includes('pastatus') || options[param][param][idx].includes('retreatment') ) {
            let isHidden = false; 
            for(let p of options[param]['hidden']) {
              if(p.includes(options[param][param][idx])) {
                isHidden = true;
                continue;
              }
            }
            if(!isHidden){
              filter.push({ value: options[param][param][idx], label: ele });
            }
          } else if (!options[param]['hidden'].includes(options[param][param][idx])) {
            filter.push({ value: options[param][param][idx], label: ele });
          }
        }
      });
      const seen = new Set();
      const filteredArr = filter.filter(el => {
        const duplicate = seen.has(el.value);
        seen.add(el.value);
        return !duplicate;
      });
      return filteredArr;
    }
    return [];
  }

  const selectID = (value,label) => {
    updateDC({value,label});
    if(value != 'narcoticoverdosemort' && value != 'fibrosis' && value != 'sobriety' && value != 'prescriber' && value != 'Prior Authorization' && value != 'Retreatment Restrictions') {
      changeDCOption('datacomparison',value);
      changeSelectedDC(value);
    } else {
      changeSelectedDC(value);
    }
    
    close();
  }
    function useOuterClick(callback) {
    const callbackRef = useRef(); // initialize mutable callback ref
    const innerRef = useRef(); // returned to client, who sets the "border" element

    // update callback on each render, so second useEffect has most recent callback
    useEffect(() => { callbackRef.current = callback; });
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (innerRef.current && callbackRef.current &&
          !innerRef.current.contains(e.target)
        ) callbackRef.current(e);
      }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself)
  }
  const innerRef = useOuterClick(ev => { close() });

  return (
    <div ref={innerRef} className="menu-nav">
      {/* <button type="button" className="btn btn-link btn-close" (click)="unCollapseDC()">Close <img src="assets/close.svg" alt=""></button> */}
      <button type="button" onClick={close} className="btn btn-link btn-close" >Close <img className="ml-1" src="assets/close_v2.svg" style={{height: '17px', paddingLeft: '0px'}} alt=""/></button>
      <div className="container" style={{display:'block'}}>
        <div className="align-items-baseline data-section">
          <div className="row">
            <div className="col-md-6">
              <h4 className="mr-2"><strong>Data Comparisons</strong></h4>
            </div>
          </div>
        <div className="row">
          <div className="col-md-6">
             <p>Select a comparison map to view alongside your current map</p>
             <div className="row">
               <div className="col-md-6" id="select-comparison">
                   <OptionComparison label={availableOpts['Poverty']!=''?availableOpts['Poverty'].label:'Poverty'} value={availableOpts['Poverty']!=''?availableOpts['Poverty'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Poverty']==''}></OptionComparison>
                   <OptionComparison label={availableOpts['High School Education']!=''?availableOpts['High School Education'].label:'High School Education'} value={availableOpts['High School Education']!=''?availableOpts['High School Education'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['High School Education']==''}></OptionComparison>
                   <OptionComparison label={availableOpts['Median Household Income']!=''?availableOpts['Median Household Income'].label:'Median Household Income'} value={availableOpts['Median Household Income']!=''?availableOpts['Median Household Income'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Median Household Income']==''}></OptionComparison>
                  <OptionComparison label={availableOpts['Income Inequality']!=''?availableOpts['Income Inequality'].label:'Income Inequality'} value={availableOpts['Income Inequality']!=''?availableOpts['Income Inequality'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Income Inequality']==''}></OptionComparison>
               </div>
               <div className="col-md-6">
                  <OptionComparison label={availableOpts['People Without Health Insurance']!=''?availableOpts['People Without Health Insurance'].label:'People Without Health Insurance'} value={availableOpts['People Without Health Insurance']!=''?availableOpts['People Without Health Insurance'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['People Without Health Insurance']==''}></OptionComparison>
                  {/* <OptionComparison label={availableOpts['Unemployment']!=''?availableOpts['Unemployment'].label:'Unemployment'} value={availableOpts['Unemployment']!=''?availableOpts['Unemployment'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Unemployment']==''}></OptionComparison>
                  <OptionComparison label={availableOpts['Severe Housing Cost Burden']!=''?availableOpts['Severe Housing Cost Burden'].label:'Severe Housing Cost Burden'} value={availableOpts['Severe Housing Cost Burden']!=''?availableOpts['Severe Housing Cost Burden'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Severe Housing Cost Burden']==''}></OptionComparison> */}
               </div>
             </div>
          </div>

          <div className="col-md-3 line-vertical" style={{padding: '0 4%'}}>
            <p >Opioid Indicators</p>
            <OptionComparison label={availableOpts['Opioid Prescription']!=''?availableOpts['Opioid Prescription'].label:'Opioid Prescription'} value={availableOpts['Opioid Prescription']!=''?availableOpts['Opioid Prescription'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Opioid Prescription']==''}></OptionComparison>
            <OptionComparison label={availableOpts['Pain Reliever Misuse']!=''?availableOpts['Pain Reliever Misuse'].label:'Pain Reliever Misuse'} value={availableOpts['Pain Reliever Misuse']!=''?availableOpts['Pain Reliever Misuse'].value:'-1'} selected={options.datacomparison.selected} selectID={selectID} disabled={availableOpts['Pain Reliever Misuse']==''}></OptionComparison>
            <OptionComparison label={availableOpts['Overdose Mortality']!=''?availableOpts['Overdose Mortality'].label:'Overdose Mortality'} value={availableOpts['Overdose Mortality']!=''?availableOpts['Overdose Mortality'].value:'-1'} selected={options.datacomparison.selected.substring(0,options.datacomparison.selected.length - 2)} selectID={selectID} disabled={availableOpts['Overdose Mortality']==''}></OptionComparison>
          </div>

          <div className="col-md-3" style={{paddingLeft: '5%'}}>
            <p>Treatment Restrictions</p>
            <OptionComparison label={availableOpts['Fibrosis Restrictions']!=''?availableOpts['Fibrosis Restrictions'].label:'Fibrosis Restrictions'} value={availableOpts['Fibrosis Restrictions']!=''?availableOpts['Fibrosis Restrictions'].value:'-1'} selected={options.datacomparison.selected.substring(0,options.datacomparison.selected.length - 2  )} selectID={selectID} disabled={availableOpts['Fibrosis Restrictions']==''}></OptionComparison>
            <OptionComparison label={availableOpts['Sobriety Restrictions']!=''?availableOpts['Sobriety Restrictions'].label:'Sobriety Restrictions'} value={availableOpts['Sobriety Restrictions']!=''?availableOpts['Sobriety Restrictions'].value:'-1'} selected={options.datacomparison.selected.substring(0,options.datacomparison.selected.length - 2  )} selectID={selectID} disabled={availableOpts['Sobriety Restrictions']==''}></OptionComparison>
            <OptionComparison label={availableOpts['Prescriber Restrictions']!=''?availableOpts['Prescriber Restrictions'].label:'Prescriber Restrictions'} value={availableOpts['Prescriber Restrictions']!=''?availableOpts['Prescriber Restrictions'].value:'-1'} selected={options.datacomparison.selected.substring(0,options.datacomparison.selected.length - 2  )} selectID={selectID} disabled={availableOpts['Prescriber Restrictions']==''}></OptionComparison>
            <OptionComparison label={availableOpts['Prior Authorization']!=''?availableOpts['Prior Authorization'].label:'Prior Authorization'} value={availableOpts['Prior Authorization']!=''?availableOpts['Prior Authorization'].value:'-1'} selected={options.datacomparison.selected.substring(0,options.datacomparison.selected.length - 2  )} selectID={selectID} disabled={availableOpts['Prior Authorization']==''}></OptionComparison>
            <OptionComparison label={availableOpts['Retreatment Restrictions']!=''?availableOpts['Retreatment Restrictions'].label:'Retreatment Restrictions'} value={availableOpts['Retreatment Restrictions']!=''?availableOpts['Retreatment Restrictions'].value:'-1'} selected={options.datacomparison.selected.substring(0,options.datacomparison.selected.length - 2  )} selectID={selectID} disabled={availableOpts['Retreatment Restrictions']==''}></OptionComparison>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dcoptions: state.dcoptions,
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeDCOption: (filter,value) => {
      dispatch({ type: 'CHANGE_SELECTED', key:filter, value });
    },
    changeSelectedDC: (selectedDCYear) => {
      dispatch({ type: 'CHANGE_SELECTED_DC', selectedDCYear})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataComparison)
