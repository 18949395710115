const initialState = { 
  dcOptions: [
      {
          "datatype": "city",
          "geotype": "zipcode",
          "datacomparison": "poverty",
          "mapvariable": "poverty",
          "maplegendvariable": "povertyquart",
          "legendvalues": "0 - 7.0,7.1 - 13.0,13.1 - 21.0,21.1+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population Living in Poverty, 2017",
          "maphover": "At the end of 2017, {varvalue}% of people were living in poverty.",
          "": ""
      },
      {
          "datatype": "city",
          "geotype": "zipcode",
          "datacomparison": "hseducation",
          "mapvariable": "educ",
          "maplegendvariable": "educquart",
          "legendvalues": "0 - 81.0,81.1 - 90.0,90.1 - 95.0,95.1+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population with a High School Education, 2017",
          "maphover": "At the end of 2017, {varvalue}% of people had earned a high school diploma or equivalent.",
          "": ""
      },
      {
          "datatype": "city",
          "geotype": "zipcode",
          "datacomparison": "medianincome",
          "mapvariable": "medincome",
          "maplegendvariable": "medincomequart",
          "legendvalues": "0 - 45000,45001 - 61000,61001 - 83000,83001+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Median Household Income, 2017",
          "maphover": "At the end of 2017, the median household income was ${varvalue}.",
          "": ""
      },
      {
          "datatype": "city",
          "geotype": "zipcode",
          "datacomparison": "incinequality",
          "mapvariable": "gini",
          "maplegendvariable": "giniquart",
          "legendvalues": "0 - 0.40,0.401 - 0.440,0.441 - 0.480,0.481+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Income Inequality (Gini Coefficient), 2017",
          "maphover": "At the end of 2017, the Gini coefficient of income inequality was {varvalue}.",
          "": ""
      },
      {
          "datatype": "city",
          "geotype": "censustract",
          "datacomparison": "poverty",
          "mapvariable": "poverty",
          "maplegendvariable": "povertyquart",
          "legendvalues": "0 - 11.0,11.1 - 20.0,20.1 - 33.0,33.1+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population Living in Poverty, 2017",
          "maphover": "At the end of 2017, {varvalue}% of people were living in poverty.",
          "": ""
      },
      {
          "datatype": "city",
          "geotype": "censustract",
          "datacomparison": "hseducation",
          "mapvariable": "educ",
          "maplegendvariable": "educquart",
          "legendvalues": "0 - 78.0,78.1 - 86.0,86.1 - 92.0,92.1+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population with a High School Education, 2017",
          "maphover": "At the end of 2017, {varvalue}% of people had earned a high school diploma or equivalent.",
          "": ""
      },
      {
          "datatype": "city",
          "geotype": "censustract",
          "datacomparison": "medianincome",
          "mapvariable": "medincome",
          "maplegendvariable": "medincomequart",
          "legendvalues": "0 - 32000,32001 - 46000,46001 - 71000,71001+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Median Household Income, 2017",
          "maphover": "At the end of 2017, the median household income was ${varvalue}.",
          "": ""
      },
      {
          "datatype": "city",
          "geotype": "censustract",
          "datacomparison": "incinequality",
          "mapvariable": "gini",
          "maplegendvariable": "giniquart",
          "legendvalues": "0 - 0.420,0.421 - 0.460,0.461 - 0.510,0.511+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Income Inequality (Gini Coefficient), 2017",
          "maphover": "At the end of 2017, the Gini coefficient of income inequality was {varvalue}.",
          "": ""
      },
      {
          "datatype": "state",
          "geotype": "",
          "datacomparison": "poverty",
          "mapvariable": "poverty",
          "maplegendvariable": "povertyquart",
          "legendvalues": "0 - 11.0,11.1 – 13.5,13.6 – 15.5,15.6+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population Living in Poverty, 2016",
          "maphover": "At the end of 2016, {varvalue}% of people were living in poverty.",
          "": ""
      },
      {
          "datatype": "state",
          "geotype": "",
          "datacomparison": "hseducation",
          "mapvariable": "educ",
          "maplegendvariable": "educquart",
          "legendvalues": "0 - 86.5,86.6 - 89.5,89.6 - 91.5,91.6+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population with a High School Education, 2016",
          "maphover": "At the end of 2016, {varvalue}% of people had earned a high school diploma or equivalent.",
          "": ""
      },
      {
          "datatype": "state",
          "geotype": "",
          "datacomparison": "medianincome",
          "mapvariable": "medincome",
          "maplegendvariable": "medincomequart",
          "legendvalues": "0 - 51000,51001 - 57000,57001 - 66000,66001+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Median Household Income, 2016",
          "maphover": "At the end of 2016, the median household income was ${varvalue}.",
          "": ""
      },
      {
          "datatype": "state",
          "geotype": "",
          "datacomparison": "incinequality",
          "mapvariable": "gini",
          "maplegendvariable": "giniquart",
          "legendvalues": "0 - 0.450,0.451 - 0.470,0.471 - 0.480,0.481+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Income Inequality (Gini Coefficient), 2016",
          "maphover": "At the end of 2016, the Gini coefficient of income inequality was {varvalue}.",
          "": ""
      },
      {
          "datatype": "state",
          "geotype": "",
          "datacomparison": "nohealthinsurance",
          "mapvariable": "unins",
          "maplegendvariable": "uninsquart",
          "legendvalues": "0 - 6.5,6.6 - 9.5,9.6 - 11.0,11.1+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population Lacking Health Insurance, 2016",
          "maphover": "At the end of 2016, {varvalue}% of people under the age of 65 were uninsured.",
          "": ""
      },
      {
          "datatype": "county",
          "geotype": "",
          "datacomparison": "poverty",
          "mapvariable": "poverty",
          "maplegendvariable": "povertyquart",
          "legendvalues": "0 - 11.5,11.6 - 15,15.1 - 19,19.1+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population Living in Poverty, 2016",
          "maphover": "At the end of 2016, {varvalue}% of people were living in poverty.",
          "": ""
      },
      {
          "datatype": "county",
          "geotype": "",
          "datacomparison": "hseducation",
          "mapvariable": "educ",
          "maplegendvariable": "educquart",
          "legendvalues": "0 - 81.5,81.6 - 87.0,87.1 - 90.5,90.6+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population with a High School Education, 2016",
          "maphover": "At the end of 2016, {varvalue}% of people had earned a high school diploma or equivalent.",
          "": ""
      },
      {
          "datatype": "county",
          "geotype": "",
          "datacomparison": "medianincome",
          "mapvariable": "medincome",
          "maplegendvariable": "medincomequart",
          "legendvalues": "0 - 41000,41001 - 48000,48001 - 50000,50001+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Median Household Income, 2016",
          "maphover": "At the end of 2016, the median household income was ${varvalue}.",
          "": ""
      },
      {
          "datatype": "county",
          "geotype": "",
          "datacomparison": "incinequality",
          "mapvariable": "gini",
          "maplegendvariable": "giniquart",
          "legendvalues": "0 - 0.420,0.421 - 0.440,0.441 - 0.470,0.471+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Income Inequality (Gini Coefficient), 2016",
          "maphover": "At the end of 2016, the Gini coefficient of income inequality was {varvalue}.",
          "": ""
      },
      {
          "datatype": "county",
          "geotype": "",
          "datacomparison": "nohealthinsurance",
          "mapvariable": "unins",
          "maplegendvariable": "uninsquart",
          "legendvalues": "0 - 7.0,7.1 - 10.5,10.6 - 14.0,14.1+",
          "legendcolors": "218-218-218-218,221-251-246-254,221-213-195-223,221-176-144-192,221-138-93-161",
          "maptitle": "Percent of Population Lacking Health Insurance, 2016",
          "maphover": "At the end of 2016, {varvalue}% of people under the age of 65 were uninsured.",
          "": ""
      }
  ] ,
  hasYear: false,
  selectedDCYear: undefined
};
  

const dcReducer = (state = initialState , action) => {
  switch(action.type) {
    case 'REPLACE_DC_OPTIONS':
      return {
        ...action.dcoptions,
        checker: Math.random()
      }
    case 'CHANGE_YEAR_DC': 
      return {
        ...state,
        hasYear: action.hasYear
      }
    case 'CHANGE_SELECTED_DC': 
      return {
        ...state,
        selectedDCYear: action.selectedDCYear
      }
    default: 
      return state;
  }
}

export default dcReducer;
