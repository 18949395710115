import {transformLegendColors} from './colorsService';


export function   errorPopup(popups, options) {
  const message = [];
  message.length = 10;
  // console.log("POPSP UERRROR", popups);
  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  if(popups) {
    for (const popup of popups) {
      if (options.overall) {
        if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected && 'overall' === popup.filter && popup.varvalue < 0) {
          if (!message[popup.varvalue]) {
            message[popup.varvalue] = '';
          }
          if(! message[popup.varvalue].includes(popup.message)) {
            message[popup.varvalue] += popup.message;
          }
        }
      }
      for (const opt of popupOptions) {
        if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
          options[opt].selected !== 'none' && opt === popup.filter && popup.varvalue < 0) {
            if (!message[popup.varvalue]) {
              message[popup.varvalue] = '';
            }
            if(! message[popup.varvalue].includes(popup.message)) {
              message[popup.varvalue] += popup.message;
            }
        }
      }
    }
  }
  return message;
}
export function searchPopups(popups, options) {
  const message = [];
  message.length = 10;
  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  for (const popup of popups) {
    if (options.overall) {
      // console.log("PIP IP ", popup.datatype, options.datatype, popup.ratescases, options.ratescases.selected);
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected && 'overall' === popup.filter) {
        if (!popup.varvalue) {
          message[popup.varorder] = popup.message;
        }
      }
    }
    for (const opt of popupOptions) {
      // death     additionalrates   aditionalrates    transcat   overall 
      // console.log('opupoptions', options.datatype, popup.ratescases, 'selected',options.ratescases.selected, "other", opt, popup.filter, options[opt] );
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        options[opt].selected !== 'none' && options[opt].selected !== '' && opt === popup.filter) {
        if (!popup.varvalue) {
          message[popup.varorder] = popup.message;
        }
      }
    }
  }
  let specialFilter = 'transcat';
  if (options.transcat['selected'] !== 'none') {
    for (const filter of popupOptions) {
      if (filter === 'transcat') {
        continue;
      }
      if (options[filter]['selected'] !== 'none') {
        specialFilter += filter;
      }
    }
  }
  if (specialFilter.length > 'transcat'.length) {
    for (const popup of popups) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        specialFilter === popup.filter) {
        if (!popup.varvalue) {
          message[popup.varorder] = popup.message;
        }
      }
    }
  }
  return message;
}

const searchHoverPopupCity = (popups, options) => {
  const message = [];
  message.length = 10;
  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  for (const popup of popups) {
    if (options.overall) {
      if (popup.hovernote) {
        message[popup.hovernote] = popup.message
      }
    }
    for (const opt of popupOptions) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        options[opt].selected !== 'none' && opt === popup.filter) {
        if (popup.hovernote) {
          message[popup.hovernote] = popup.message
        }
      }
    }
  }
  let specialFilter = 'transcat';
  if (options.transcat['selected'] !== 'none') {
    for (const filter of popupOptions) {
      if (filter === 'transcat') {
        continue;
      }
      if (options[filter]['selected'] !== 'none') {
        specialFilter += filter;
      }
    }
  }
  if (specialFilter.length > 'transcat'.length) {
    for (const popup of popups) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        specialFilter === popup.filter) {
        if (popup.hovernote) {
          message[popup.hovernote] = popup.message
        }
      }
    }
  }
  return message;
}
///////////
const searchDC = (options,comparisonOptions) => {
  // console.log("SEARCHING DC", options.datacomparison, comparisonOptions);
  const ans = comparisonOptions.filter(ele => {
    if (options.maptype === 'city') {
      // tslint:disable-next-line:max-line-length
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && options.geographytype['selected'] === ele.geotype;
    } else {
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison;
    }
  });
  return ans;
}
const errorPopupDC = () => {
  return 'Data not available because the data source does not publish these data for this jurisdiction. ';
}
///////////
const searchPopupCity = (popups, options) => {
  const message = [];
  message.length = 10;
  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  // console.log("CITY", options.overall, options.datatype, options.ratescases.selected);
  // console.log("POPUPS ", popups);
  // console.log(popupOptions, options['age'],options['race'],options['sex'],options['transcat']);
  for (const popup of popups) {
    if (options.overall) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected && 'overall' === popup.filter) {
        if(popup.varvalue?popup.varvalue>0:true) {
          message[popup.varorder] = popup.message;            
        }
      }
    }
    for (const opt of popupOptions) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        options[opt].selected !== 'none' && opt === popup.filter) {
          if(popup.varvalue?popup.varvalue>0:true) {
            message[popup.varorder] = popup.message;
          }
      }
    }
  }
  let specialFilter = 'transcat';
  if (options.transcat['selected'] !== 'none') {
    for (const filter of popupOptions) {
      if (filter === 'transcat') {
        continue;
      }
      if (options[filter]['selected'] !== 'none') {
        specialFilter += filter;
      }
    }
  }
  if (specialFilter.length > 'transcat'.length) {
    for (const popup of popups) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        specialFilter === popup.filter) {
        message[popup.varorder] = popup.message;
      }
    }
  }
  return message;
}
const errorPopupCity = (popups, options) => {
  // -4 is the lowest value
  const message = [];
  message.length = 10;
  const popupOptions = ['age', 'race', 'sex', 'transcat'];
  for (const popup of popups) {
    if (options.overall) {
      // console.log(popup.varvalue);
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected && 'overall' === popup.filter && popup.varvalue < 0) {
        message[popup.varvalue] = popup.message;
      }
    }
    for (const opt of popupOptions) {
      if (popup.datatype === options.datatype && popup.ratescases === options.ratescases.selected &&
        options[opt].selected !== 'none' && opt === popup.filter && popup.varvalue < 0) {
        message[popup.varvalue] = popup.message;
      }
    }
  }
  return message;
}

export const getStatePopUpHTML = ( state, popupsData, options, stateValues,  setPopupHTML) => {
    let messages = searchPopups(popupsData, options);
      let advertise = messages[3];
      let message = messages[0];
      let message2 = '';
      // setMessage(messages[0]);
      if(messages[1] && messages[1]!='undefined') {
        message2 = (`<div style="margin-top:5px; margin-bottom:0px; line-height: 1.1;"><em><small>${messages[1]}</small></em></div>`);
      }
      let value = '';
      let pos = -1;
      let yn = false;
      let corrwarning = 0;
      let showButton = '';
      let yearExtra = 'none';
      if(options.maptype == 'state') {
        for (const data of stateValues) {
          if (data.state === state) {
            yearExtra = data.year?data.year:yearExtra;
            value = data.value;
            pos = data.pos;
            yn = data.yn;
            corrwarning = data.corrwarning;
          }
        }
      } 
      if (+value < 0) {
        const errorPopups = errorPopup(popupsData, options);
        // {showButton}  esto falta poner pero todavia no se qués
        let error = `<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr><div style="line-height:1;"> ${errorPopups[+value]} </div>`
        if (!yn) {
          // pos 2 in csv file
          let message2 = '<div style="line-height:1; margin-top:5px; margin-bottom:0px; ">' + messages[2] + '</div>';
          message2 = message2.includes('undef') ? '' : message2;
          error += message2;
        }
        if (advertise && (options.datatype === 'PrEP' || options.datatype === 'PNR')) {
          // // console.log('Adv');
          error += '<div style="line-height:1; margin-top:5px; margin-bottom:0px; line-height: 1.1">' + advertise + '</div>';
        }
        // mapService.addPopup(event.lngLat, error); < = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
        // console.log("THIS ",error);
        setPopupHTML(error);
        // if (!isHover) {
        //   let pin = document.getElementById(`pin${counter}`);
        //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
        // }
        return;
      }
      if (+value >= 1000) {
        let point = 0;
        if (+value >= 1000) {
          point = 1;
        }
        if (+value >= 10000) {
          point = 2;
        }
        if (+value >= 100000) {
          point = 3;
        }
        value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
      }

      message = message ? message.replace('{varvalue}', '' + value): message;
      if(yearExtra != 'none') {
        message = message.replace('{year}', '' + yearExtra);
      } else {
        message = message ? message.replace('{year}', '' + options.year['selected']): message;
      }
      const legendcolorsArray = transformLegendColors(options['legendcolors']);
      let colorRow = '<table style="width:100%"> <tr >';
      const divide = 100 / legendcolorsArray.length;
      legendcolorsArray.forEach( (element, index) => {
        if (index === pos) {
          colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
        } else {
          colorRow += `<th width="${divide}%"> &nbsp; </th>`;
        }
      });
      colorRow += '</tr><tr>';
      for (const color of legendcolorsArray) {
        colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
      }
      colorRow += '</tr> </table>';
      if (yn && !corrwarning) {
      // // console.log('corrwarning 2', corrwarning);
        message = '<div style="line-height:1; margin-bottom:0px;"> ' + message + '</div>';
        
        if (advertise && (options.datatype === 'PrEP' || options.datatype === 'PNR')) {
          // tslint:disable-next-line:max-line-length
          // AQUI ESTA EL POPUP ARMADO     < = = = == = = = = = = = = = = = = = ==  = == = = = = = = ==  == = = 
          // console.log("THIS ASCK",`<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message} ${message2} <div style="margin-top:5px; margin-bottom:0px; line-height: 1.1;"> ${advertise} </div>  ${colorRow} <hr>`);
          setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${message} ${message2} <div style="margin-top:5px; margin-bottom:0px; line-height: 1.1;"> ${advertise} </div>  ${colorRow} <hr>`);
          // mapService.addPopup(event.lngLat, `${showButton}<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message} ${message2} <div style="margin-top:5px; margin-bottom:0px; line-height: 1.1;"> ${advertise} </div>  ${colorRow} <hr>`);
        } else {
          // // console.log('EMEM', `${showButton}<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message}  ${colorRow} <hr>`);
          // AQUI ESTA EL POPUP ARMADO 2  < = = = == = = = = = = = = = = = = = ==  = == = = = = = = ==  == = = 
          // console.log(" CHECK 3", `<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message} ${message2}  ${colorRow} <hr>`);
          setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${message} ${message2}  ${colorRow} <hr>`);
          // mapService.addPopup(event.lngLat, `${showButton}<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message} ${message2}  ${colorRow} <hr>`);
        }
        // if (!isHover) {
        //   let pin = document.getElementById(`pin${counter}`);
        //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
        // }
      } else {
        if (!yn) {
          // pos 2 in csv file
          let message2 = '<br><div style="line-height:1; margin-top:5px; margin-bottom:0px; ">' + messages[2] + '</div>';
          message2 = message2.includes('undef') ? '' : message2;
          message += message2;
          // // console.log('Message 1', message);
          if (advertise && (options.datatype === 'PrEP' || options.datatype === 'PNR') ) {
            message += '<div style="line-height:1; margin-top:5px; margin-bottom:0px; line-height: 1.1">' + advertise + '</div>';
            // // console.log('Message 2', message);
          }
        }
        message = '<div style="line-height:1; margin-bottom:0px;"> ' + message + '</div>';
        // AQUI ESTA EL POPUP ARMADO 3 < = = = == = = = = = = = = = = = = = ==  = == = = = = = = ==  == = = 
        // console.log("MADRE PATRI ", `<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message}  ${colorRow} <hr>`);
        setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${message}  ${colorRow} <hr>`);
        // mapService.addPopup(event.lngLat, `${showButton}<h5 style="font-weight: bold"> ${state} </h5> <hr> ${message}  ${colorRow} <hr>`);
        // if (!isHover) {
        //   let pin = document.getElementById(`pin${counter}`);
        //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
        // }
      }
}
export const getCountyPopUpHTML = ( geo_id2, county, state, popupsData, options, countyValues, stateCodes, setPopupHTML) => {
  const messages = searchPopups(popupsData, options);
  // console.log("COUTY POPUP", messages , "COUNTY VALUES ", countyValues);
  let advertise = messages[3];
  // const counter = mapService.infinite_counter;
  let message = messages[0];
  // console.log('message on init', message);
  let message2 = messages[1]  ? (`<div style="margin-top:5px; margin-bottom:0px; line-height: 0.6;"><small>${messages[1]}</small></div>`) : '';
   message2 = message2.includes('undef') ? '' : message2;
  let headerMessage = '';
  stateCodes.forEach(stateCode => {
    if (stateCode.state === state) {
      headerMessage = stateCode.name;
    }
  });
  let value = '';
  let pos = -1;
  let yn = false;
  let corrwarning = 0;
  let showButton = '';
  let yearExtra = 'none';
  // if (!isHover) {
  //   showButton = `<button class="pin active" id="pin${counter}" type="button" name="button" class="btn" placement="right"><i class="mdi mdi-pin"></i></button>`;
  //   mapService.infinite_counter++;
  // }
  
  for (const data of countyValues) {
    if (data.geo_id2 === geo_id2) {
      yearExtra = data.year?data.year:yearExtra;
      value = data.value;
      pos = data.pos;
      yn = data.yn;
      corrwarning = data.corrwarning;
      county = data.countyname?data.countyname: data.county;
    }
  }
  if (+value < 0) {
    const errorPopups = errorPopupCity(popupsData, options);
    let error = `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"> <h5 style="font-weight: bold"> ${county} </h5> </b> <hr> <div style="line-height:1;">  ${errorPopups[+value]?errorPopups[+value]:errorPopups[-1]}  </div>`;
    if (!yn) {
      // pos 2 in csv file
      let   message2 = '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[2] + '</div>';
      message2 = message2.includes('undef') ? '' : message2;
      error += message2;
    }
    if (advertise && !(options.datatype === 'PrEP' || options.datatype === 'PNR') ) {
      advertise = ""; // clear because request
      error += '<div style=" margin-top:5px; margin-bottom:0px; line-height: 0.7">' + advertise + '</div>';
    } else if (corrwarning == 1 && (options.datatype !== 'PrEP' && options.datatype !== 'PNR') && messages[3]) {
      // pos 3 in csv file
      error += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
    }
    error = error.replace('{state}', '' + headerMessage);
    // mapService.addPopup(event.lngLat, );
    setPopupHTML(error)
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
    return;
  }
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  const legendValues = options.legendvalues.split(',');
  message = message.replace('{varvalue}', '' + value);
  if(yearExtra != 'none') {
    message = message.replace('{year}', '' + yearExtra);
  } else {
    message = message.replace('{year}', '' + options.year['selected']);
  }
  const legendcolorsArray = transformLegendColors(options['legendcolors']);
  let colorRow = '<table style="width:100%"> <tr >';
  const divide = 100 / legendcolorsArray.length;
  legendcolorsArray.forEach( (element, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  for (const color of legendcolorsArray) {
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  colorRow += '</tr> </table>';
  if (yn && corrwarning) {
    message = '<div style="line-height:1; margin-bottom:0px;"> ' + message + '</div>';
    if (corrwarning == 1 && !((options.datatype === 'PrEP' || options.datatype === 'PNR'))) {
      // pos 3 in csv file
      const messagePos3 = messages[2] ? messages[2] : '';
      message += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messagePos3 + '</div>';
    }
    if (advertise && (options.datatype === 'PrEP' || options.datatype === 'PNR')) { 
      advertise = ""; // clear because request
      // console.log("POPUP 1", message, message2);
      setPopupHTML(`<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"> <h5 style="font-weight: bold"> ${county} </h5> </b> <hr> ${message} ${message2} ${colorRow} <hr>`);
      // mapService.addPopup(event.lngLat, `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <h5 style="font-weight: bold"> ${county} </h5> <hr> ${message} ${message2} ${colorRow} <hr>`);
    } else {
      // console.log("POPUP 2", message, "A", message2);
      setPopupHTML(`<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"><h5 style="font-weight: bold"> ${county} </h5></b> <hr> ${message}  ${colorRow} <hr>`);
      // mapService.addPopup(event.lngLat, `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <h5 style="font-weight: bold"> ${county} </h5> <hr> ${message}  ${colorRow} <hr>`);
    }
    
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
  } else {
    if (!yn && !((options.datatype === 'PrEP' || options.datatype === 'PNR'))) {
      // pos 2 in csv file
      const messagePos3 = messages[2] ? messages[2] : '';
      let message2 = '<br><div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messagePos3 + '</div>';
      message2 = message2.includes('undef') ? '' : message2;
      message += message2;
    }
    if (corrwarning == 1 && !((options.datatype === 'PrEP' || options.datatype === 'PNR'))) {
      // pos 3 in csv file
      message += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
    }
    message = '<div style="line-height:1; margin-bottom:0px;"> ' + message + '</div>';
    if (options.datatype === 'PrEP' || options.datatype === 'PNR') { 
      // console.log("POPUP 3", message, message2);
      setPopupHTML(`<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"><h5 style="font-weight: bold"> ${county} </h5></b> <hr> ${message} ${message2} ${colorRow} <hr>`);
      // mapService.addPopup(event.lngLat, `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <h5 style="font-weight: bold"> ${county} </h5> <hr> ${message} ${message2} ${colorRow} <hr>`);
    } else { 
      // console.log("POPUP 4", message);
      setPopupHTML(`<h6 style="font-weight: bold"> ${headerMessage} </h6> <b style="font-weight: 900;"><h5 style="font-weight: bold"> ${county} </h5> </b> <hr> ${message}  ${colorRow} <hr>`);
      // mapService.addPopup(event.lngLat, `${showButton}<h6 style="font-weight: bold"> ${headerMessage} </h6> <h5 style="font-weight: bold"> ${county} </h5> <hr> ${message}  ${colorRow} <hr>`);
    }
    
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
  }
}

export const getCityPopUpHTML = ( properties, popupsData, options, cityValues,setPopupHTML) => {
  let city = properties.city;
  const zip = properties.zip;
  const census = properties.geoid10;
  const cartodb_id = properties.cartodb_id;
  const area = properties.area_numbe;
  let community = properties.community;
  const ward = properties.ward;
  if (community > 0) {
    community = community.split(' ');
    const community_string = [];
    for (let com of community) {
      com = com.substring(0, 1) + com.substring(1).toLowerCase();
      community_string.push(com);
    }
    community = community_string.join(' ');
  }
  const label = properties.label;
  let messages = searchPopupCity(popupsData, options);
  // const counter = mapService.infinite_counter++;
  let message = messages[0];  
  let showButton = '';
  // if (!isHover) {
  //   showButton = `<button class="pin active" id="pin${counter}" type="button" name="button" class="btn" placement="right"><i class="mdi mdi-pin"></i></button>`;
  //   mapService.infinite_counter++;
  // }
  const hoverMessages = searchHoverPopupCity(popupsData, options);
  let value = '';
  let pos = -1;
  let yn = false;
  let corrwarning = 0;
  let hovernote = '';
  let year = '';
  
  for (const data of cityValues) {
    if (options.geographytype['selected'] === 'zipcode') {
      if (data.zip === zip) {
        let zipCode = '' + zip;
        while (zipCode.length < 5) {
          zipCode = '0' + zipCode;
        }
        value = data.value;
        city = data.cityname + `, Zip Code ${zipCode}`;
        pos = data.pos;
        yn = data.yn;
        corrwarning = data.corrwarning;
        hovernote = data.hovernote;
        year = data.year?data.year:(data.yearrange?data.yearrange:'none');
      }
    }
    if (options.geographytype['selected'] === 'censustract') {
      if (+data.census === +census) {
        value = data.value;
        city = data.cityname + `, Census Tract ${census}`;
        pos = data.pos;
        yn = data.yn;
        corrwarning = data.corrwarning;
        hovernote = data.hovernote;
        year = data.year?data.year:(data.yearrange?data.yearrange:'none');
      }
    }
    if (options.geographytype['selected'] === 'communityarea') {
      if (data.area === area) {
        value = data.value;
        city = data.cityname + `, ${data.neighborhood ? data.neighborhood : ''} neighborhood`;
        pos = data.pos;
        yn = data.yn;
        corrwarning = data.corrwarning;
        hovernote = data.hovernote;
        year = data.year?data.year:(data.yearrange?data.yearrange:'none');
      }
    }
    if (options.geographytype['selected'] === 'ward') {
      if (data.ward === ward) {
        value = data.value;
        city = data.cityname + `, ${label}`;
        pos = data.pos;
        yn = data.yn;
        corrwarning = data.corrwarning;
        hovernote = data.hovernote;
        year = data.year?data.year:(data.yearrange?data.yearrange:'none');
      }
    }
  }
  if (+value < 0) {
    const errorPopups = errorPopupCity(popupsData, options);
    let error = `${showButton}<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${city} </h5></b>  <hr> <div style="line-height:1;"> ${errorPopups[+value]} </div>`;
    if (!yn) {
      // pos 2 in csv file
      if (messages[2]) {
        error += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[2] + '</div>';
      }
    }
    if (corrwarning == 1) {
      // pos 3 in csv file
      if (messages[3]) {
        error += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
      }
    }
    if (hovernote) {
      if (hoverMessages[hovernote]) {
        error += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + hoverMessages[hovernote] + '</div>';
      }
    }
    // mapService.addPopup(event.lngLat, error);
    setPopupHTML(error);
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
    return;
  }
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  const legendValues = options.legendvalues.split(',');
  message = message?message.replace('{varvalue}', '' + value):'';
  message = message?message.replace('{year}', '' + year):'';
  message = message?message.replace('{yearrange}', '' + year):'';
  const legendcolorsArray = transformLegendColors(options['legendcolors']);
  let colorRow = '<table style="width:100%"> <tr >';
  const divide = 100 / legendcolorsArray.length;
  legendcolorsArray.forEach( (element, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  for (const color of legendcolorsArray) {
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  colorRow += '</tr> </table>';
  if (yn && corrwarning) {
    if(typeof messages[1] !== 'undefined') {
      message += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[1] + '</div>'
    }
    message = '<div style="line-height:1; margin-bottom:0px;"> ' + message + '</div>';
   
    if (corrwarning == 1) {
      // pos 3 in csv file
      message += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
    }
    let popupMessage = `${showButton}<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${city} </h5></b> <hr> ${message}  ${colorRow} <hr>`;
    if (hovernote) {
      const newHoverMessage = '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + hoverMessages[hovernote] +'</div>';
      popupMessage  = `${showButton}<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${city} </h5></b> <hr> ${message} ${newHoverMessage} ${colorRow} <hr>` ;
    }
    // mapService.addPopup(event.lngLat, popupMessage);
    setPopupHTML(popupMessage);
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
  } else {
    if(typeof messages[1] !== 'undefined') {
      message += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[1] + '</div>'
    }
    if (!yn) {
      // pos 2 in csv file
      if ( typeof messages[2] !== 'undefined') {
        message += '<br><div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[2] + '</div>';
      }
    }
    if (corrwarning == 1) {
      // pos 3 in csv file
      message += '<div style="line-height:1; margin-top:5px; margin-bottom:0px;">' + messages[3] + '</div>';
    }
    message = '<div style="line-height:1; margin-bottom:0px;"> ' + message + '</div>';
    let popupMessage = `${showButton}<h5 style="font-weight: bold"> ${city} </h5> <hr> ${message}  ${colorRow} <hr>`;
    if (hovernote) {
      popupMessage = `${showButton}<h5 style="font-weight: bold"> ${city} </h5> <hr> ${message} <div style="line-height:1; margin-top:5px; margin-bottom:0px;">${hoverMessages[hovernote]}</div>  ${colorRow} <hr>`;
    }
    // mapService.addPopup(event.lngLat, popupMessage);
    setPopupHTML(popupMessage);
    // if (!isHover) {
    //   let pin = document.getElementById(`pin${counter}`);
    //   pin.addEventListener('click', attachPopup.bind(this, event, `pin${counter}`));
    // }
  }
}

export const getCountyComparerPopUpHTML = (geo_id2, county, state, popupsData, options, dataComparisonOptions, stateCodes, setPopupHTML, comparisonOptions) => {
  const self = this;
  // if( !dataComparisonOptions ) {
  //   dataComparisonOptions = globals.datacomparisonOptions;
  // } 
  // const countys = mapService.map2.queryRenderedFeatures(event.point, {
  //   layers: ['county-hover']
  // });
  // if(!countys[0]){
  //   return;
  // }
  let headerMessage = '';
  stateCodes.forEach(stateCode => {
    if (stateCode.state === state) {
      headerMessage = stateCode.name;
    }
  });
  let value = '';
  let pos = -1;
  let dcyear = 2018;
  dataComparisonOptions.forEach((element, index) => {
    if (element.geo_id2 === geo_id2) {
      value = element.value?element.value:value;
      dcyear = element.year?element.year:dcyear;
      pos = element.legendpos?(element.legendpos - 1):pos;
    }
  });
  if (+value === -9) {
    setPopupHTML(`<h4>${headerMessage}</h4> <b style="font-weight: 900;"> <h5 style="font-weight: bold"> ${county} </h5> </b> <hr> ${errorPopupDC()} <br>`);
    return;
  }
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  // if( options.datacomparison['selected'] == 'none') {
  //   options.datacomparison['selected'] = globals.dcSelected;
  // }

  const comparer = searchDC(options, comparisonOptions)[0];
  let message = comparer.maphover;
  message =  message.replace('{varvalue}', value) ;
  message =  message.replace('{year}', dcyear) ;
  const availableColors =   comparer['legendcolors'];
  
  const legendcolorsArray = transformLegendColors(availableColors);
  let colorRow = '<table style="width:100%"> <tr >';
  const divide = 100 / legendcolorsArray.length;
  legendcolorsArray.forEach( (element, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  for (const color of legendcolorsArray) {
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  colorRow += '</tr> </table>';
  message = '<div style="line-height:1; margin-bottom:5px;"> ' + message + '</div>';
  setPopupHTML(`<h5 style="font-weight: bold"> ${headerMessage} </h5> <b style="font-weight: 900;"> <h5 style="font-weight: bold"> ${county} </h5> </b> <hr> ${message} ${colorRow} <hr>`);
}
export const getStateComparerPopUpHTML = (state, popupsData, options, dataComparisonOptions,  setPopupHTML, comparisonOptions) => {
  // const self = this;
  // if( !dataComparisonOptions ) {
  //   dataComparisonOptions = globals.datacomparisonOptions;
  // } 
  let value = '';
  let pos = -1;
  let year = 2018;
  dataComparisonOptions.forEach((element, index) => {
    if (element.state === state) {
      value = element.value;
      pos = element.legendpos - 1;
      year = element.year;
    }
  });
  const comparer = searchDC(options, comparisonOptions)[0];
  let message = comparer.maphover;
  if (+value === -9) {
    setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${errorPopup()} <br>`);
    return;
  }
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  message = message.replace('{varvalue}', value);
  message = message.replace('{year}', year);
  message = message.replace('{state}', state);
  // /'0-0-0-0,0-0-0-0,0-0-0-0,' +
  const availableColors =   comparer['legendcolors'];
  const legendcolorsArray = transformLegendColors(availableColors);
  let colorRow = '<table style="width:100%"> <tr >';
  const divide = 100 / legendcolorsArray.length;
  legendcolorsArray.forEach( (element, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  for (const color of legendcolorsArray) {
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  colorRow += '</tr> </table>';
  message = '<div style="line-height:1; margin-bottom:5px;"> ' + message + '</div>';
  setPopupHTML(`<b style="font-weight: 900;"><h5 style="font-weight: bold"> ${state} </h5></b> <hr> ${message} ${colorRow} <hr>`);
}
export const getCityComparerPopUpHTML = ( properties, popupsData, options, cityValues,setPopupHTML, comparisonOptions) => {
  let city = properties.city;
  const zip = properties.zip;
  const census = properties.geoid10;
  let value = '';
  let pos = -1;
  let year = 2018;
  cityValues.forEach((element, index) => {
    if (options.geographytype['selected'] === 'zipcode') {
      if (element.zipcensus === zip) {
        value = element.value;
        year = element.year?element.year:year;
        pos = element.legendpos - 1;
        city = `${element.city}, Zip Code ${zip}`;
      }
    } else {
      if (+element.zipcensus === +census) {
        value = element.value;
        year = element.year?element.year:year;
        pos = element.legendpos - 1;
        city = `${element.city}, Census Tract ${census}`;
      }
    }
  });
  
  if (+value === -9 || +value === -4) {
    setPopupHTML(` <h5 style="font-weight: bold"> ${city} </h5> <hr> ${errorPopup()} <br>`);
    return;
  }
  const comparer = searchDC(options,comparisonOptions)[0];
  let message = comparer.maphover;
  if (+value >= 1000) {
    let point = 0;
    if (+value >= 1000) {
      point = 1;
    }
    if (+value >= 10000) {
      point = 2;
    }
    if (+value >= 100000) {
      point = 3;
    }
    value = value.toString().substring(0, point) + ',' + value.toString().substr(point);
  }
  message = message.replace('{varvalue}', value);
  if (options.year['selected'] != 'none' && options.year['selected'] != 'hidden') {
    message = message.replace('{year}', '' + options.year['selected']);
  } else {
    message = message.replace('{year}', year);
  }

  const availableColors =  '0-0-0-0,0-0-0-0,0-0-0-0,' + comparer['legendcolors'];
  const legendcolorsArray = transformLegendColors(availableColors);
  let colorRow = '<table style="width:100%"> <tr >';
  const divide = 100 / legendcolorsArray.length;
  legendcolorsArray.forEach( (element, index) => {
    if (index === pos) {
      colorRow += `<th class="text-center" width="${divide}%"> <i class="mdi mdi-arrow-down-bold"> </i> </th>`;
    } else {
      colorRow += `<th width="${divide}%"> &nbsp; </th>`;
    }
  });
  colorRow += '</tr><tr>';
  for (const color of legendcolorsArray) {
    colorRow += `<th style="background-color:${color} !important"> &nbsp;</th>`;
  }
  colorRow += '</tr> </table>';
  message = '<div style="line-height:1; margin-bottom:5px;"> ' + message + '</div>';
  setPopupHTML( `<h5 style="font-weight: bold"> ${city} </h5> <hr> ${message} ${colorRow} <hr>`);
}