import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { loadStatePrint } from './../services/stateService';
import { exportModelDefault, printDefault} from './utils';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

import print from './DownloadMapService';
import { updateFilters } from '../services/mapService';

const DownloadMapModal = ({
    close, options, changeArea, overlays,
    printImage, changeService, printValues,
    startLoader, endLoader, changeDraw, changeDrawFalse
  }) => {
  const [isDistricts, setIsDistricts] = useState(false);
  const [dropdownBaseMapOpen, setdropdownBaseMapOpen] = useState(false);
  const [dropdownPrintOpen, setdropdownPrintOpen] = useState(false);
  const [exportModel, setExportModel] = useState(exportModelDefault);
  const [printModel, setPrintModel] = useState(printDefault);
  const [drawState, setDrawState] = useState(false);
  const updateBaseMap = (name) => {
    setExportModel({...exportModel, selectedBaseMap: name});
  }
  const updateArea = (name) => {
    setExportModel({...exportModel, selectedArea: name});
    if(name === 'On Display') {
      return ;
    }
    let areaValue = name;
    if ( name.includes('North'))  { areaValue = 'region_north'; }
    if ( name.includes('South')) { areaValue = 'region_south' ; }
    if ( name == 'West') { areaValue = 'region_west' ; }
    if ( name == 'Midwest') { areaValue = 'region_midwest'; }
    changeArea(areaValue);
  }

  const printMap = () => {
    startLoader()
    print(exportModel, options, printModel, overlays, printImage, printValues, endLoader);
  }

  useEffect(()=>{
    let doc = document.getElementById('BASEMAP');
    if(doc) {
      doc.classList.remove('btn');
      doc.classList.remove('btn-secondary');
    }
    doc = document.getElementById('AREA');
    if(doc) {
      doc.classList.remove('btn');
      doc.classList.remove('btn-secondary');
    }

    fetchData();
  },[]);

  useEffect(()=>{
    setIsDistricts(overlays.isDistricts);
  },[overlays.isDistricts]);
  const fetchData = async () => {
    let rows = await loadStatePrint();
    rows.sort((a, b) => {
      return a.abbrev.localeCompare(b.abbrev);
    });
    let newDD = { ...printModel }
    for (let row of rows) {
      newDD.states.push(row.abbrev);
      newDD.statesInfo.push({abbrev: row.abbrev, state: row.state, region: row.region});
    }
    newDD.states = newDD.states.sort();
    setPrintModel(newDD);
  }

  const updateCheckBox = (label) => {
    if (label === 'isDistricts') {
      changeService(label, !overlays.isDistricts);
    } else {
      setPrintModel({
        ...printModel,
        [label]: !printModel[label]
      })
    }
  }
  const updateDraw = () => {
    changeDraw();
    setDrawState(!drawState);
  }
  const closeModal = () => {
    close(false);
    changeDrawFalse();
  }
  return (
    <div className="menu-panel menu-panel-auto" >
      <button type="button" className="btn btn-link btn-close" onClick={() => closeModal()}>
        Close
        <img src="assets/close_v2.svg" alt="" className="ml-1" style={{height: '17px'}}/>
      </button>
      <div className="container menu-download-map">
          <h6>
            <strong>
              Download Map
            </strong>
          </h6>
          
          <div className="d-flex flex-row align-items-end">
            <div className="form-group">
              <label>Basemap</label>

              <Dropdown className="btn-group " isOpen={dropdownBaseMapOpen} toggle={() => setdropdownBaseMapOpen(!dropdownBaseMapOpen)}>
                <DropdownToggle caret className="btn-pt dropdown-toggle white-background" id="BASEMAP">
                  {exportModel.selectedBaseMap}
                  <i className="mdi mdi-menu-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {
                    exportModel.basemap.map((name) => (
                      <DropdownItem key={ 'di-' + name+Math.random() } onClick={() => updateBaseMap(name)}>
                          {name}
                      </DropdownItem>
                    ))
                  }
                </DropdownMenu>
              </Dropdown>
            </div>
            <strong className="text-gray py-4 mx-3"></strong>
            
            <span className="form-group">
              <label>Print Area</label>
              <Dropdown className="btn-group " isOpen={dropdownPrintOpen} toggle={() => setdropdownPrintOpen(!dropdownPrintOpen)}>
              <DropdownToggle caret className="btn-pt dropdown-toggle white-background" id="AREA">
                {exportModel.selectedArea}
                <i className="mdi mdi-menu-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                {
                  exportModel.printArea.map((name) => (
                    <DropdownItem key={ 'di-' + name+Math.random() } onClick={() => updateArea(name)}>
                        {name}
                    </DropdownItem>
                  ))}
                {
                  printModel.states.map((name) => (
                    <DropdownItem key={ 'di-' + name+Math.random() } onClick={() => updateArea(name)}>
                        {name}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>
            </span>
            {/* <strong className="text-gray py-4 mx-3">(or)</strong> */}
            {/* <span className="form-group"> */}
              {/* <label>Bounding Box</label> */}
              {/* (click)="addDraw()" [style.border]="!draw ? 'solid 2px #f4f4f4' : 'solid 1px #f1f1f1'" [ngClass]="{'disabled':options.maptype==='city'}" */}
              {/* <button type="button" name="button" className={"btn bt-area " + (options.maptype ==='city'? 'disabled':'')} onClick={() => updateDraw()}> */}
                {/* [src]="blink" */}
                {/* <img  width="18px" alt="" src={drawState?"assets/icon@2x_dark.png":"assets/icon@2x.png"} /> */}
                {/* </span> */}
              {/* </button> */}
            {/* </span> */}
          </div>
          <div className="form-group">
            <label className="controlbox controlbox--checkbox mb-1">
              <input type="checkbox" className="checkbox-print" onChange={() => updateCheckBox('title')} checked={printModel.title} />
              <div className="controlbox__indicator"></div>
              <strong>Title</strong>
            </label>
            <label className="controlbox controlbox--checkbox mb-1">
              <input type="checkbox" className="checkbox-print" onChange={() => updateCheckBox('legend')} checked={printModel.legend} />
              <div className="controlbox__indicator"></div>
              <strong>Legend</strong>
            </label>
            <label className="controlbox controlbox--checkbox">
              <input type="checkbox" className="checkbox-print" onChange={() => updateCheckBox('footer')} checked={printModel.footer} />
              <div className="controlbox__indicator"></div>
              <strong>Footer</strong>
            </label>
            {/* <label className="controlbox controlbox--checkbox">
              <input type="checkbox"  className="checkbox-print" onChange={() => updateCheckBox('isDistricts')} checked={isDistricts} />
              <div className="controlbox__indicator"></div>
              <strong>Congresional Districts</strong>
            </label> */}
          </div>
        <div className="d-flex flex-row">
          <button type="button" className="btn btn-panel btn-primary" id="download-map" onClick={printMap}>Export</button>
          <button type="button" className="btn btn-panel btn-outline-secondary ml-3" onClick={() => close(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = (state) => {
  return {
    options: state.options,
    overlays: state.overlays,
    printImage: state.printImage,
    printValues: state.printValues
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoader: () => {
      dispatch({ type: 'START_LOADER'})
    }, 
    endLoader: () => {
      dispatch({ type: 'END_LOADER'})
    },
    changeArea: (value) => {
      dispatch({type: 'CHANGE_AREA',value})
    },
    changeService: (key, value) => {
      dispatch({type:'CHANGE_SERVICE', key, value})
    },
    changeDraw: () => {
      dispatch({type:'CHANGE_DRAW'})
    },
    changeDrawFalse: () => {
      dispatch({type:'END_DRAW'})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadMapModal);
