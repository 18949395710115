import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

const DatasetDropdown = ({options, changeDatatype}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [items, setItems] = useState([
    { label: 'Hepatitis C Prevalence', key: 'chronic', ind: 'prev' },
    { label: 'Hepatitis C Mortality', key: 'death', ind: 'newdx' },
    { label: 'Opioid Prescription', key: 'opioidprescriptionrate' },
    { label: 'Pain Reliever Misuse', key: 'painrelievermisuse', ind: 'prep' },
    { label: 'Overdose Mortality', key: 'narcoticoverdosemortality', ind: 'pnr' },
    // { label: 'Injection-Involved Overdose', key: 'Injdeathspct', ind: 'injdeathspct' }
  ]);
  const [dataset, setDataset] = useState(items[0]);
  const changeDataSet = (value)=> {
    setDataset(value);
    changeDatatype(value.key);
  }
  useEffect(()=>{
    setTimeout(() => {
      let item = items.find( element => element.key === options.datatype);
      if(item) {
        setDataset(item);
      }
    },400);
  },[options.datatype]);
  useEffect(()=>{
    setDataset({ label: 'Prevalence', key: 'prevalence', ind: 'prev' });
    switch(options.maptype) {
      case 'county': 
      setDataset({ label: 'Hepatitis C Mortality', key: 'death', ind: 'newdx' });
        return  setItems([
          { label: 'Hepatitis C Mortality', key: 'death', ind: 'newdx' },
          { label: 'Opioid Prescription', key: 'opioidprescriptionrate' },
          { label: 'Overdose Mortality', key: 'narcoticoverdosemortality', ind: 'pnr' }
        ])
      case 'state':
        return setItems([
          { label: 'Hepatitis C Prevalence', key: 'chronic', ind: 'prev' },
          { label: 'Hepatitis C Mortality', key: 'death', ind: 'newdx' },
          { label: 'Opioid Prescription', key: 'opioidprescriptionrate' },
          { label: 'Pain Reliever Misuse', key: 'painrelievermisuse', ind: 'prep' },
          { label: 'Overdose Mortality', key: 'narcoticoverdosemortality', ind: 'pnr' },
          // { label: 'Injection-Involved Overdose', key: 'Injdeathspct', ind: 'injdeathspct' }
        ])
      case 'city':
        return  setItems([
          { label: 'Prevalence', key: 'prevalence', ind: 'prev' },
          { label: 'New Diagnoses', key: 'newdx', ind: 'newdx' },
          { label: 'Late Diagnoses', key: 'latedx', ind:'latedx' },
          { label: 'Linkage to Care', key: 'linkage', ind: 'link' },
          { label: 'Receipt of Care', key: 'receipt', ind: 'receipt' },
          { label: 'Viral Suppression', key: 'viralsuppression' , ind: 'viral'},
        ])

    }
    
  },[options.maptype])
  useEffect(()=>{
    if(options.maptype == 'city' && options.indicators) {
      let newItems = [];
      for(const value of items){
        if(options.indicators.includes(value.ind)) {
          newItems.push(value);
        }
      }
      setItems(newItems);
      setDataset({ label: 'Hepatitis C Prevalence', key: 'chronic', ind: 'prev' });
    }
  },[options.indicators]);
  return (
    <Dropdown className="btn-group mr-4" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle caret>
        <small>{dataset.label}</small>
        <i className="mdi mdi-menu-down icon-lg "></i>
      </DropdownToggle>
      <DropdownMenu id="select-dataset">
        {
          items.map(({ label, key }) => (
            <DropdownItem key={ 'di-' + key } onClick={() => changeDataSet({ label, key })}>
              {label}
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  )
}

const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeDatatype: (value) => {
      dispatch({ type: 'CHANGE_VALUE', key:'datatype', value });
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatasetDropdown)