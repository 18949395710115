export  const defaultOptions = {
  city: '',
  hasYear: false,
  year: {
    year: ['2000','2001','2002','2003','2004','2005','2006','2007','2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017','2018','2019','2020'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  ratescases: {
    ratescases: ['rates', 'cases'],
    labels: ['Rates', 'Cases'],
    selected: 'rates',
    available: [],
    hidden: [],
    unavailable: []
  },
  rateslabel: '',
  caseslabel: '',
  additionalrateslabel: '',
  overall: true,
  age: {
    age: ['agele24', 'age1324', 'age2534', 'age2544', 'age3544','agelt40'	,'age40over', 'agelt50', 'age4554', 'age4559', 'age5074', 'age55over', 'age60over', 'age75over'],
    labels: ['24 and under', '13 to 24', '25 to 34', '25 to 44', '35 to 44', 'Less than 40' , '40+' , 'Less than 50', '45 to 54', '45 to 59', '50 to 74 (Baby Boomer)', '55+', '60+', '75+'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  race: {
    race: ['aiannh', 'apinh', 'black', 'hispanic', 'nhpi', 'multirace', 'white', 'nonblack'],
    labels: ['American Indian or Alaska Native', 'Asian or Pacific Islander', 
    'Black', 'Hispanic/Latinx', 'Native Hawaiian/Pacific Islander', 'Multiple Races', 'White', 'Non-Black'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  sex: {
    sex: ['male', 'female'],
    labels: ['Male', 'Female'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  transcat: {
    transcat: ['msm', 'msmidu', 'idu', 'heterosexual', 'other'],
    labels: ['Male-to-Male Sexual Contact', 'Male-to-Male Sexual Contact / Injection Drug Use',
    'Injection Drug Use', 'Heterosexual Contact', 'Other'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  datacomparison: {
    datacomparison: ['poverty', 'hseducation', 'medianincome', 'incinequality', 'nohealthinsurance', 'opioidprescription', 'painrelievermisuse', 'narcoticoverdosemort','fibrosis','sobriety','prescriber', 'pastatus','retreatment'],
    labels: ['Poverty', 'High School Education', 'Median Household Income', 'Income Inequality', 'People without Health Insurance', 'Opioid Prescription', 'Pain Reliever Misuse', 'Overdose Mortality','Fibrosis Restrictions','Sobriety Restrictions','Prescriber Restrictions', 'Prior Authorization', 'Retreatment Restrictions'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  geographytype: {
    geographytype: ['zipcode', 'censustract', 'communityarea', 'neighborhood', 'ward', 'none'],
    labels: ['Zip Code', 'Census Tract', 'Community Area', 'Neighborhood', 'Ward', 'none'],
    selected: 'none',
    available: [],
    hidden: [],
    unavailable: []
  },
  datatype: 'death',
  datatypecontrol: 'available',
  mainmaptable: '',
  mainmaptable2: '',
  mainmapvariable: '',
  mainmaplegendvariable: '',
  legendvalues: '',
  legendcolors: '',
  mainmaptitle: '',
  footnotes: '',
  maptype: 'county',
  areaType: {value:'all', name:'All Counties'}
};