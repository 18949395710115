const frameLimitsDefault = {
  height: 0,
  width: 0,
  border: '0',
  selected: {
    text: '1440px x 900px',
    height: 900,
    width: 1440
  },
  sizes: [
    {
      text: '100% Width',
      height: 0,
      width: 100
    },
    {
      text: '2880px x 1800px',
      height: 1800,
      width: 2880
    },
    {
      text: '2160px x 1350px',
      height: 1350,
      width: 2160
    },
    {
      text: '1440px x 900px',
      height: 1800,
      width: 2880
    },
    {
      text: '720px x 450px',
      height: 450,
      width: 720
    },
    {
      text: '432px x270px',
      height: 270,
      width: 432
    }
  ]
};

const exportModelDefault = {
  basemap: ['Normal', 'Road', 'White', 'Transparent'],
  sizes: [ 
    {'name': 'Low', 'height':0,'width':0, 'disabled':false, family: 'low' },
    {'name': 'High', 'height':0,'width':0, 'disabled':false, family: 'high' }
  ],
          // {'name': 'Print', 'height':0,'width':0, 'disabled':true },
          // {'name': '612 x 324 (8.5in x 5.5in)', 'width':612,'height':324,'disabled':false, 'family':'Print' },
          // {'name': '792 x 612 (11in x 8.5in)', 'width':792,'height':612 ,'disabled':false,'family':'Print' },
          // {'name': '2592 x 1728 (36in x 24in)', 'width':2592,'height':1728, 'disabled':false,'family':'Print' },
          // {'name': 'Social', 'width':0,'height':0, 'disabled':true},
          // {'name': '1024 x 512', 'width':1024, 'height':512, 'disabled':false,'family':'Social' },
          // {'name': '1080 x 1080', 'width':1080, 'height':1080, 'disabled':false,'family':'Social' },
          // {'name': '1200 x 630', 'width':1200, 'height':630, 'disabled':false,'family':'Social' },
          // {'name': '1200 x 675', 'width':1200, 'height': 675, 'disabled':false,'family':'Social' },
          // {'name': 'Digital', 'width':0,'height':0, 'disabled':true },
          // {'name': '1920 x 1080', 'width':1920, 'height':1080, 'disabled':false,'family':'Digital' },
          // {'name': '3840 x 2160', 'width':3840, 'height':2160, 'disabled':false,'family':'Digital' } ],
  printArea: ['On Display','All', 'North', 'Midwest', 'South', 'West'],
  width: 612,
  maxwidth: 7000,
  height: 324,
  maxheight: 7000,
  format: ['png', 'jpg'],
  selectedFormat: 'png',
  selectedArea: 'On Display',
  selectedBaseMap: 'Normal',
  selectedSize:'High',
  selectedFamily:'high'
};

const dropdownFrameDefault = {
  regions: [
    {text: 'On Display', value: 'display'},
    {text: 'All', value: 'All'},
    {text: 'North', value: 'region_north'},
    {text: 'Midwest', value: 'region_midwest'},
    {text: 'South', value: 'region_south'},
    {text: 'West', value: 'region_west'}      
  ],
  selectedRegion: {text: 'All', value: 'All'},
  maps: [{text: 'Without basemap', value: 'cjanwn8woeys92ro7v1ibuf1c'},
  {text: 'Roads map', value: 'cjjopdw9d0bk12rs0ic8qi2fh'},
  {text: 'Normal', value: 'cj9ogyubd4ebv2rrr3nibqotp'}],
  selectedMap: {text: 'Normal', value: 'cjjopfjjb03ag2rpnrv3w5k62'}
};
const printDefault = {
  open: false,
  basemap: true,
  resolution: 'Normal',
  area: [],
  footer: true,
  title: true,
  legend: true,
  hideMore: true,
  states: [],
  statesInfo: []
};

const extractQueryParams = () => {
  let searchParams = new URLSearchParams(window.location.search)
  let paramsCount = 0;
  let obj = {
    maptype: '',
    datatype: '',
    ratescases: '',
    overall: false,
    basemap: undefined,
    filters: undefined,
    area: undefined,
    overlays: undefined,
    city:undefined,
    geotype:undefined
  }

  for (let p of searchParams) {
    let [key, value] = p;
    if (key && value) {
      if(key == 'filters') {
        const filters = value.split('|'); 
        obj[key] = filters;
        obj['overall'] = filters.includes('overall');
      }  else if( key === 'overlays' ) {
        const overlays = value.split('|');
        obj[key] = overlays;
      } else if ( key === 'year' && value != '') {
        obj[key] = value;
      } else {
        obj[key] = value;
        paramsCount++;
      }
    }
  }
  return [obj, paramsCount];
}

export {frameLimitsDefault, exportModelDefault, dropdownFrameDefault, printDefault, extractQueryParams}