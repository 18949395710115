import React, { useState } from 'react';
import DownloadDataModal from './DownloadDataModal';
import DownloadMapModal from './DownloadMapModal';
import MoreInformationModal from './MoreInformationModal';
import ShareAidsvuModal from './ShareAidsvuModal';
import { connect } from 'react-redux';
import TutorialPopup from './TutorialPopup';
const BottomToolbar = ({options, isDC, title}) => {
  const [openDownloadDataModal, setOpenDownloadDataModal] = useState(false);
  const [openDownloadMapModal, setOpenDownloadMapModal] = useState(false);
  const [openShareAidsvuModal, setOpenShareAidsvuModal] = useState(false);
  const [openMoreInformationModal, setOpenMoreInformationModal] = useState(false);
  const [openTutorialModal, setOpenTutorialModal ] = useState(false);

  /* [ngStyle]="{'bottom':largeTitleCheck()?'44px':'28px'}" [ngClass]="(options.datacomparison.selected=='none' )?'':'bottom-2'" */
  const openModal = ( optModal) => {
    switch(optModal) {
      case 'downloaddata':
        setOpenDownloadDataModal(!openDownloadDataModal);
        setOpenDownloadMapModal(false);
        setOpenShareAidsvuModal(false)
        setOpenTutorialModal(false);
        setOpenMoreInformationModal(false);
        return ;
      case 'downloadmap':
        setOpenDownloadMapModal(!openDownloadMapModal)
        setOpenDownloadDataModal(false);
        setOpenShareAidsvuModal(false)
        setOpenTutorialModal(false);
        setOpenMoreInformationModal(false);
        return ;
      case 'share':
        setOpenShareAidsvuModal(!openShareAidsvuModal);
        setOpenDownloadDataModal(false);
        setOpenDownloadMapModal(false);
        setOpenTutorialModal(false);
        setOpenMoreInformationModal(false);
        return ;
      case 'tutorial':
        setOpenTutorialModal(!openTutorialModal);
        setOpenDownloadDataModal(false);
        setOpenDownloadMapModal(false);
        setOpenShareAidsvuModal(false)
        setOpenMoreInformationModal(false);
        return ;
      case 'moreinformation':
        setOpenMoreInformationModal(!openMoreInformationModal)
        setOpenDownloadDataModal(false);
        setOpenDownloadMapModal(false);
        setOpenShareAidsvuModal(false)
        setOpenTutorialModal(false);
        return;
      default:
        return ;
    }
  }
  return (
    <>
      {
        openDownloadDataModal && <DownloadDataModal close={setOpenDownloadDataModal} />
      }
      {
        openDownloadMapModal && <DownloadMapModal close={setOpenDownloadMapModal}/>
      }
      {
        openShareAidsvuModal && <ShareAidsvuModal close={setOpenShareAidsvuModal} />
      }
      {
        openMoreInformationModal && <MoreInformationModal close={setOpenMoreInformationModal} />
      }
      <TutorialPopup isOpen={openTutorialModal} setIsOpen={setOpenTutorialModal} />
      <div className={'fixed-bottom navbar-menu-bottom ' + ((!isDC && (title.length > 55))?'':'')}>
        <nav className="navbar navbar-menu p-0">
          {
            options.maptype != 'city' &&
            <div className={"navbar-menu-option mobile-hidden " + (openDownloadDataModal?'active':'')} onClick={() => openModal('downloaddata')}>
              <img className="navbar-menu-icon" src="assets/ic_download-data-white.svg" alt="" />
              <span>
                Download data
              </span>
            </div>
          }
          <div className={"navbar-menu-option "+ (openDownloadMapModal?'active':'')} onClick={() => openModal('downloadmap')}>
            <img className="navbar-menu-icon" src="assets/icon-001.svg" alt="" />
            <span>
              Download map
            </span>
          </div>
          <div className={"navbar-menu-option " + (openShareAidsvuModal?'active':'')} onClick={() => openModal('share')}>
            <img className="navbar-menu-icon" src="assets/icon-002.svg" alt="" />
            <span>
              Share
            </span>
          </div>
          <div className={"navbar-menu-option mobile-hidden " + (openTutorialModal?'active':'')} onClick={() => openModal('tutorial')}>
            <img className="navbar-menu-icon" src="assets/ic_tutorial-white.svg" alt="" />
            <span>Tutorial</span>
          </div>
          <div className={"navbar-menu-option " + (openMoreInformationModal?'active':'')} onClick={() => openModal('moreinformation')}>
            <img className="navbar-menu-icon" src="assets/ic_info-white.svg" alt="" />
            <span>
              More information
            </span>
          </div>
        </nav>
      </div>
    </>
  )

}

const mapStateToProps = (state) => {
  return {
    options: state.options,
    isDC: state.options.datacomparison.selected && state.options.datacomparison.selected !== '' && state.options.datacomparison.selected !== 'none'
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BottomToolbar)
