import React ,{useEffect, useState} from 'react';
import { loadStatePrint } from './../services/stateService';
import { connect } from 'react-redux';
import {defaultOptions} from './../defaultOptions';
import {FacebookShareButton, FacebookIcon, TwitterShareButton} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {printService} from './../services/printService';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert
} from 'reactstrap';
import { dropdownFrameDefault, frameLimitsDefault} from './utils';
const mapboxgl = {
  LngLat: function(lng, lat){
    this.lng = lng;
    this.lat = lat;
  }
}
const ShareAidsvuModal = ({ close, changeArea,  options, changeBaseMap, overlaysValues}) => {
  const [dropdownAreaOpen, setDropdownAreaOpen] = useState(false);
  const [dropdownMapOpen, setDropdownMapOpen] = useState(false);

  const [dropdownAreaOpen2, setDropdownAreaOpen2] = useState(false);
  const [dropdownMapOpen2, setDropdownMapOpen2] = useState(false);

  const [dropdownFrameOpen, setDropdownFrameOpen] = useState(false);
  const [openCopyDS, setOpenCopyDS] = useState(false);
  const [openCopyIF, setOpenCopyIF] = useState(false);
  const [border, setBorder] = useState(2);

  const [dropdownFrame, setDropdownFrame] = useState( dropdownFrameDefault );
  const [frameLimits, setFrameLimits ] = useState( frameLimitsDefault);
  const [_frameSD, setFrameSD] = useState('');
  const [_iFrame, setIFrame] = useState('');
  let frame = '';
  let embedCopy = undefined;
  let shareCopy = undefined;
  let shareCopy2 = undefined;
  let yearFrame = null;

  let serviceModel = {
    hivTesting: false,
    prep: false,
    hivRyanwhite: false,
    hopwa: false,
    hptnMtn: false,
    hptn: false,
    mtn: false,
    hvtn: false,
    actgImpaact: false,
    actg: false,
    impaact: false,
    hcs: false
  };
  const ChangeBorders = (e)=>{
    frameLimits.border = e;
    setBorder(e);
    
  }
  useEffect(()=>{
    getFrame();
  },[border]);
  const getFrame = () => {
    let frameSd = '';
    let iFrame = '';
    embedCopy = false;
    shareCopy = false;
    shareCopy2 = false;
    let windowshref = window.location.href; 
    let index = windowshref.indexOf('?');
    if(index > 5 ) {
      windowshref = windowshref.substring(0,index);
    }
    
    const hrefNoFrame = windowshref ;
    let href = windowshref.replace('org/map', 'org/');
    let type = '';
    if (options.maptype === 'state') {
      type = 'state-frame';
    }
    if (options.maptype === 'county') {
      type = 'county-frame';
    }
    let extras = '';
    if (options.maptype === 'city') {
      type = 'city-frame';
      extras += '&city=' + options.city;
      extras += '&geotype=' + options.geographytype.selected;
    }
    let filters = '';
    for (const opt in options) {
      if (options[opt]) {
        if (options[opt].hasOwnProperty('selected')) {
          // // console.log(options[opt]['selected']);
        }
        if (options[opt].hasOwnProperty('selected') && opt !== 'ratescases'
        && opt !== 'geographytype'
        && options[opt]['selected'] !== 'none' && options[opt]['selected']) {
          const selected = options[opt]['selected'];
          if (filters) {
            filters += '|';
          }
          if (opt === 'year') {
            yearFrame = selected;
            continue;
          }
          filters += options[opt]['labels'][options[opt][opt].indexOf(selected)];
        }
      }
    }
    if (!filters) {
      filters = 'overall';
    }
    if (defaultOptions['datacomparison'].labels.includes(filters)) {
      filters += '|overall';
    }
    // const bounds = mapService.getBounds();
    let overlays = '';
    for( const opt in overlaysValues) {
      if(overlaysValues[opt]) {
        overlays += opt;
        if(overlays){
          overlays += '|';
        }
      }
    }
    // const rBounds = [[bounds.getNorthWest().lng, bounds.getNorthWest().lat], [bounds.getSouthEast().lng, bounds.getSouthEast().lat]];
    frame = href + type + '?maptype='+options.maptype+'&datatype=' + options.datatype
    + '&ratescases=' + options.ratescases.selected + '&filters=' + filters + 
    // '&bounds=[[' + rBounds[0][0] + ','
    // + rBounds[0][1] + '],[' + rBounds[1][0] + ',' + rBounds[1][1] + ']]' + 
    extras;
    frameSd = hrefNoFrame +'?maptype='+options.maptype+'&datatype=' + options.datatype
    + '&ratescases=' + options.ratescases.selected + '&filters=' + filters + '&overlays='+ overlays + extras;
    if(dropdownFrame.selectedRegion.value == 'display') {
      let sw = new mapboxgl.LngLat(printService.nw[0], printService.se[1]);
      let ne = new mapboxgl.LngLat(printService.se[0], printService.nw[1]);
      frame += '&bounds=' + `[[${sw.lng},${sw.lat}],[${ne.lng},${ne.lat}]]`;;
      frameSd += '&bounds=' + `[[${sw.lng},${sw.lat}],[${ne.lng},${ne.lat}]]`;;
    } else if (dropdownFrame.selectedRegion.value || dropdownFrame.selectedMap.value) {
      frame += '&area=' + dropdownFrame.selectedRegion.value;
      frameSd += '&area=' + dropdownFrame.selectedRegion.value;
    }
    if ( dropdownFrame.selectedMap ) {
      frameSd += '&basemap='+dropdownFrame.selectedMap.value;
      frame += '&basemap='+dropdownFrame.selectedMap.value;
    }
    if (yearFrame) {
      frame += '&year=' + yearFrame;
      frameSd += '&year=' + yearFrame;
    }
    frameSd = encodeURI(frameSd);
    
    frame = encodeURI(frame);
    let newBorder = border;
    if (!border) {
      newBorder = '0';
    }
    if (frameLimits.selected.width === 100) {
      iFrame = `<iframe src="${frame}"width='${frameLimits.selected.width}%' height='${frameLimits.selected.width}%' style='border:${newBorder}px solid black;' ></iframe>`;
    } else {
      iFrame = `<iframe src="${frame}"width='${frameLimits.selected.width}' height='${frameLimits.selected.height}' style='border:${newBorder}px solid black;' ></iframe>`;
    }
    setIFrame(iFrame);
    setFrameSD(frameSd);
    return true;
  }
  useEffect(() => {
    const fetchData = async () => {
      let rows = await loadStatePrint();
      rows.sort((a, b) => {
        return a.abbrev.localeCompare(b.abbrev);
      });
      let newDD = { ...dropdownFrame }
      for (let row of rows) {
        // this.print.states.push(row.abbrev);
        newDD.regions.push({text: row.abbrev, value: row.abbrev});
        // this.print.statesInfo.push({abbrev: row.abbrev, state: row.state, region: row.region});
      }
      setDropdownFrame(newDD);
    }
    fetchData();
  },[]);
  useEffect(()=>{
    getFrame();
  },[options, overlaysValues, dropdownFrame.selectedRegion, dropdownFrame.selectedMap, frameLimits.selected])
  const selectRegionFrame = (value) => {
    dropdownFrame.selectedRegion = value;
    if(value.value == 'display') {
      return;
    }
    
    let areaValue = value.text;
    if ( value.text.includes('North'))  { areaValue = 'region_north'; }
    if ( value.text.includes('South')) { areaValue = 'region_south' ; }
    if ( value.text.includes('West')) { areaValue = 'region_west' ; }
    if ( value.text.includes('Midwest')) { areaValue = 'region_midwest'; }
    changeArea(areaValue);
  }
  const selectMapFrame = (value) => {
    dropdownFrame.selectedMap = value;
    changeBaseMap(value.value);
  }
  const updateFrameLimits = (value)=>{
    let newFL = frameLimits;
    newFL.selected = value;
    setFrameLimits(newFL);
  }

  const copyFrameSD = ()=> {
    // navigator.clipboard.writeText(_frameSD);
    setOpenCopyDS(true);
    setTimeout(()=>{
      setOpenCopyDS(false);
    },2000);
  }
  const copyFrameIF = ()=> {
    // navigator.clipboard.writeText(_iFrame);
    setOpenCopyIF(true);
    setTimeout(()=>{
      setOpenCopyIF(false);
    },2000);
  }
  return (
    <div className="menu-panel">
      <button type="button" className="btn btn-link btn-close" onClick={() => close(false)}>
        Close <img src="assets/close_v2.svg" alt="" className="ml-1" style={{height: '17px'}}/>
      </button>
      <h6><strong>Share HepVu Map via Social</strong></h6>
      <div className="d-flex flex-row align-items-end flex-wrap-mobile">
        <span className="form-group mr-3">
          <label>Area</label>

            <Dropdown className="btn-group " isOpen={dropdownAreaOpen} toggle={() => setDropdownAreaOpen(!dropdownAreaOpen)} disabled={options.maptype === 'city'}>
            <DropdownToggle caret className={"font-size-color btn btn-px dropdown-toggle dropdown-border white-background " + (options.maptype === 'city'?'disabled':'')}>
              {dropdownFrame.selectedRegion.text}
              <i className="mdi mdi-menu-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              {
                dropdownFrame.regions.map((value) => (
                  <DropdownItem key={ 'di-' + value.text } onClick={() => selectRegionFrame(value)}>
                      {value.text}
                  </DropdownItem>
                ))
              }
            </DropdownMenu>
          </Dropdown>
        </span>
        <span className="form-group">
          <label>Basemap</label>
          
          <Dropdown className="btn-group " isOpen={dropdownMapOpen} toggle={() => setDropdownMapOpen(!dropdownMapOpen)}>
            <DropdownToggle caret className="font-size-color btn btn-px dropdown-toggle dropdown-border white-background">
              {dropdownFrame.selectedMap.text}
              <i className="mdi mdi-menu-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              {
                dropdownFrame.maps.map((value) => (
                  <DropdownItem key={ 'di-' + value.text } onClick={() => selectMapFrame(value)}>
                      {value.text}
                  </DropdownItem>
                ))
              }
            </DropdownMenu>
          </Dropdown>
        </span>
      </div>
      <div className="form-group">
        <label>Copy and Paste to share a link to the map on display</label>
        <div className="input-group mb-3">
          {/* [(ngModel)]="frameSd" */}
          <input type="text" className="form-control" defaultValue={_frameSD} placeholder="Export to iFrame" aria-label="Recipient's username" aria-describedby="basic-addon2" />
          <div className="input-group-append">
            {/* (click)="shareCopy2 = true;"  [cbContent]="frameSd" */}
            <CopyToClipboard text={ _frameSD} onCopy={()=> copyFrameSD()} id="share-map">
              <button className="btn btn-copy input-group-text" id="basic-addon2" > COPY</button>
            </CopyToClipboard>
          </div>
        </div>
        {/* *ngIf="shareCopy2"  */}
        <Alert type="success" className="alert2" isOpen={openCopyDS}>
          Success, your link has been copied!
          {/* (click)="shareCopy2=false"  */}
            {/* <button type="button" name="button" className="btn bg-transparent"> <i className="mdi mdi-window-close"></i> </button> */}
        </Alert>
        <span className="d-flex align-items-center">
          <span className="mr-2">Share on:</span>
          {/* [sbUrl]="frame" */}
          <FacebookShareButton 
                url={_frameSD}>
                 <img src="assets/ic_facebook-red.svg" alt="" />
          </FacebookShareButton>
          {/* <a className="mr-2 cursor-share" shareButton="facebook"></a> */}
          {/* [sbUrl]="frame" */}
          {/* <a className="cursor-share"  shareButton="twitter"><img src="assets/ic_twitter-red.svg" alt="" /></a>       */}
          <div className="mr-2"></div>
          <TwitterShareButton 
                url={_frameSD}>
                 <img src="assets/ic_twitter-red.svg" alt="" />
          </TwitterShareButton>
        </span>
      </div>

      <div className="mobile-hidden">
        <hr />
        <h6><strong>Embed HepVu Map</strong></h6>
        <div className="d-flex flex-row align-items-end flex-wrap-mobile">
          <span className="form-group mr-3">
            <label>Area</label>
            <Dropdown className="btn-group " isOpen={dropdownAreaOpen2} toggle={() => setDropdownAreaOpen2(!dropdownAreaOpen2)} disabled={options.maptype === 'city'}>
              <DropdownToggle caret className={"font-size-color btn btn-px dropdown-toggle dropdown-border white-background " + (options.maptype === 'city'?'disabled':'')}>
                {dropdownFrame.selectedRegion.text}
                <i className="mdi mdi-menu-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                {
                  dropdownFrame.regions.map((value) => (
                    <DropdownItem key={ 'di-' + value.text } onClick={() => selectRegionFrame(value)}>
                        {value.text}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>
          </span>
          <span className="form-group">
            <label>Basemap</label>
              <Dropdown className="btn-group " isOpen={dropdownMapOpen2} toggle={() => setDropdownMapOpen2(!dropdownMapOpen2)}>
                <DropdownToggle caret className="font-size-color btn btn-px dropdown-toggle dropdown-border white-background">
                  {dropdownFrame.selectedMap.text}
                  <i className="mdi mdi-menu-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {
                    dropdownFrame.maps.map((value) => (
                      <DropdownItem key={ 'di-' + value.text } onClick={() => selectMapFrame(value)}>
                          {value.text}
                      </DropdownItem>
                    ))
                  }
                </DropdownMenu>
              </Dropdown>
          </span>
        </div>
        <div className="d-flex flex-row align-items-end flex-wrap-mobile">
          <span className="form-group mr-3">
            <label>Size (16:9)</label>
            <Dropdown className="btn-group " isOpen={dropdownFrameOpen} toggle={() => setDropdownFrameOpen(!dropdownFrameOpen)}>
              <DropdownToggle caret className="font-size-color btn btn-px dropdown-toggle dropdown-border white-background">
                {frameLimits.selected.text}
                <i className="mdi mdi-menu-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                {
                  frameLimits.sizes.map((value) => (
                    <DropdownItem key={ 'di-' + value.text } onClick={() => updateFrameLimits(value)}>
                        {value.text}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </Dropdown>
          </span>
          <span className="form-group">
            <label>Border</label>
            <div className="modal-ifrmae form-control p-0 position-relative">
            <div className="pixel">px</div>
              {/* (keypress)="getFrame()" (change)="getFrame()" (keyup)="getFrame()" (keydown)="getFrame()" [(ngModel)]="frameLimits.border" */}
              <input  type="number" className="border-0 form-control pr-31px" placeholder="2px" aria-label="Username" aria-describedby="basic-addon1" onInput={(e)=>ChangeBorders(e.target.value)}/> 
            </div>
          </span>
        </div>
        <div className="form-group">
          <label>Copy and Paste this code in your website</label>
          <div className="input-group mb-2">
            {/* [(ngModel)]="iFrame" */}
            <input  type="text" className="form-control" defaultValue={_iFrame} placeholder="Export to iFrame" aria-label="Recipient's username" aria-describedby="basic-addon2" />
            <div className="input-group-append">
              {/* (click)="embedCopy = true" */}
              {/* [cbContent]="iFrame" */}

 
              <CopyToClipboard text={_iFrame} onCopy={() => copyFrameIF()} id="share-iframe"> 
                  <button    className="btn btn-copy input-group-text" id="basic-addon2" onClick={()=> copyFrameIF()}>COPY</button>
              </CopyToClipboard>
            </div>
          </div>
          <small className="mt-2 text-gray">Help about embedding  <i className="mdi mdi-information" popover="To embed an HepVu map into your website, select the state, county, or city map you want to embed. Use the options above to select a basemap type, size, and border size to customize your map. You can also select any desired Filter Options. Then, copy and paste the iFrame code into your blog or web page."
            placement="top" triggers="mouseenter:mouseleave" container="body"></i></small>
            {/* *ngIf="embedCopy" */}
            <Alert type="success" className="alert2" isOpen={openCopyIF}> 
              Success, your iframe has been copied!
              {/* (click)="embedCopy=false" */}
              {/* <button  type="button" name="button" className="btn btn-x"> <i className="mdi mdi-window-close"></i> </button> */}
            </Alert>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    options: state.options,
    overlaysValues: state.overlays,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeArea: (value) => {
      dispatch({type: 'CHANGE_AREA',value})
    },
    changeBaseMap: (value) => {
      dispatch({type: 'CHANGE_BASEMAP', value})
    },
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareAidsvuModal);
