import {environment} from './environments/environment';

export const SERVER = (function() {
  // console.log("ENVIRONMENT", environment);
  const BASE_URL = environment.BASE_URL;
 //  const BASE_URL = 'http://13.58.251.188:8080';
  // const BASE_URL = '/api'; //for secure connection
  const PROD_URL = 'http://3.14.33.123:8080';
  const DEV_URL = 'http://3.14.105.20:8080';
  const AIDS_URL = 'http://18.221.34.152:8080';
  return {
      BASE_URL: BASE_URL,
      COUNTY_FILTERS: `${BASE_URL}/county-query`,
      STATES_FILTERS: `${BASE_URL}/state-query`,
      CITY_GEOTYPE: `${BASE_URL}/cityoptions`,
      PRINT: `https://map.hepvu.org/api/print`,
      // PRINT_COMPARISON: `${BASE_URL}/print-comparison`,
      PRINT_COMPARISON: `https://map.hepvu.org/api/print-comparison`,
      CITY_POPUPS: `${BASE_URL}/popupbycity`,
      COUNTY_POPUPS: `${BASE_URL}/popup-county`,
      STATE_POPUPS: `${BASE_URL}/popup-state`,
      LOCAL_STATISTICS: `${BASE_URL}/localstatistics`,
      UPLOAD_PERM: `${DEV_URL}/update-permutation`,
      UPLOAD_POP: `${DEV_URL}/update-popup`,
      UPLOAD_PROD_PERM: `${PROD_URL}/update-permutation`,
      UPLOAD_PROD_POP: `${PROD_URL}/update-popup`,
      UPLOAD_DC: `${BASE_URL}/update-datacomparison-csv`,
      DELETE_DC: `${BASE_URL}/delete_dc`,
      GET_DC: `${BASE_URL}/datacomparison`,
      GET_CSV: `${BASE_URL}/getcsv`,
      DEV_URL: DEV_URL,
      AIDS_URL: AIDS_URL
  };
} )();


export const CARTO = (function () {
  const USERNAME = environment.carto_account;
  const API_KEY = environment.carto_api_key;
  const BASE_URL = `https://${USERNAME}.carto.com/api/v2`;
  const API_KEY_PARAMETER = `&api_key=${API_KEY}`;
  const DISTRICT_TABLE = 'congressional_districts_118';
  const FORMAT = '&format=GeoJSON';
  const TABLE = environment.prefix + 'nih_data_site_listing_2017';
  const STATE_TABLE = environment.prefix + 'state2010';
  const COUNTY_TABLE = environment.prefix + 'counties2010';
  const COUNTY_TABLE2 = 'counties2020';
  const STATE_COMPARER = environment.STATE_COMPARISON_TABLE;
  const STATE_COMPARER_YEAR = environment.STATE_COMPARISON_TABLE_YEARS;
  const COUNTY_COMPARER = 'table_2022countydatacomparisonmaps2017';
  const CITY_ZIP_COMPARER = 'table_2018zipprev2016';
  const CITY_CENSUS_COMPARER = 'table_2018censusprev2016';
  const CITY_TABLE = environment.prefix + 'allcities_zipcode_2017';
  const CITY_ZIP_CODE = environment.prefix + 'allcities_zipcode_2017';
  const CITY_CENSUS = environment.prefix + 'allcities_census_2017';
  const CITY_WARD = environment.prefix + 'dc_ward_2017';
  const CITY_COMMUNITY = environment.prefix + 'chicago_community_area_2017';
  const HIV_TABLE = environment.prefix + 'hiv_testing_sites';
  const HOUSING_TABLE = environment.prefix + 'mlexport_aidsvu_2016hopwa';
  const PREP_TABLE = environment.prefix + 'prep_services';
  const RYAN_TABLE = environment.prefix + 'primary_care_hpsa_points';
  return {
    API_KEY: API_KEY,
    API_KEY_PARAMETER: API_KEY_PARAMETER,
    BASE_URL: BASE_URL,
    CITY_CENSUS: CITY_CENSUS,
    CITY_COMMUNITY: CITY_COMMUNITY,
    CITY_TABLE: CITY_TABLE,
    CITY_WARD: CITY_WARD,
    CITY_ZIP_CODE: CITY_ZIP_CODE,
    CITY_ZIP_COMPARER: CITY_ZIP_COMPARER,
    CITY_CENSUS_COMPARER: CITY_CENSUS_COMPARER,
    COUNTY_TABLE: COUNTY_TABLE,
    COUNTY_TABLE2: COUNTY_TABLE2,
    COUNTY_COMPARER: COUNTY_COMPARER,
    DISTRICT_TABLE: DISTRICT_TABLE,
    GET_QUERY: function (sql, geojson) {
      let url = `${BASE_URL}/sql?q=${sql}${API_KEY_PARAMETER}`;
      if (geojson) {
        url = url + FORMAT;
      }
      return url;
    },
    FORMAT: FORMAT,
    HIV_TABLE: HIV_TABLE,
    HOUSING_TABLE: HOUSING_TABLE,
    PREP_TABLE: PREP_TABLE,
    RYAN_TABLE: RYAN_TABLE,
    STATE_TABLE: STATE_TABLE,
    STATE_COMPARER: STATE_COMPARER,
    STATE_COMPARER_YEAR: STATE_COMPARER_YEAR,
    TABLE: TABLE,
    USERNAME: USERNAME
  };
})();

// export const MAPZEN = (function () {
//   const API_KEY = 'mapzen-2s1uEQU';
//   const BASE_URL = `https://search.mapzen.com/v1`;
//   const API_KEY_PARAMETER = `&api_key=${API_KEY}`;
//   const AUTOCOMPLETE_URL = `${BASE_URL}/autocomplete`;
//   const TEXT_PARAMETER = '&text=';
//   const COUNTRY_PARAMETER = '&boundary.country=USA';
//   return {
//     API_KEY: API_KEY,
//     BASE_URL: BASE_URL,
//     API_KEY_PARAMETER: API_KEY_PARAMETER,
//     AUTOCOMPLETE_URL: AUTOCOMPLETE_URL,
//     TEXT_PARAMETER: TEXT_PARAMETER,
//     COUNTRY_PARAMETER: COUNTRY_PARAMETER,
//     GET_AUTOCOMPLETE: function (text) {
//       let url = `${AUTOCOMPLETE_URL}?${TEXT_PARAMETER}${text}${COUNTRY_PARAMETER}${API_KEY_PARAMETER}`;
//       return url;
//     },
//   };
// })();
