
export function transformLegendColors (legend) {
  const splittedColors = legend.split(',');
  let aux;
  const res = new Array();
  // Convert values of legendcolors to an array with
  // rgba(155,21,132,0.45)
  for (let entry of splittedColors){
    if(entry != '218-218-218-218'){     
      if (entry.split('-').length < 4) {
        entry = entry.trim();
        entry = '255-' + entry;
      }
      const rgb = entry.split('-');
      aux = 'rgba(';
      for (let i = 1; i < 4; i++) {
        aux += rgb[i] + ',';
      }
      aux += transformOpacity(rgb[0]) + ')';
      res.push(aux);
    }
  }
  // for (let i = 0; i < 4; ++i) {
  //   res.shift();
  // }
  return res;
}
export function transformLegendColorsToHex(legend) {
  const splittedColors = legend.split(',');
  let aux;
  const res = new Array();
  // Convert values of legendcolors to an array with
  // rgba(155,21,132,0.45)
  for (let entry of splittedColors){
    if (entry.split('-').length < 4) {
      entry = entry.trim();
      entry = '255-' + entry;
    }
    const rgb = entry.split('-');
    aux = '#';
    for (let i = 1; i < 4; i++) {
      let color = (+rgb[i]).toString(16);
      if (color.length === 1) {
        color = '0' + color;
      }
      aux += color;
    }
    const opacity = transformOpacity(rgb[0]);
    const opacity255 = (Number(rgb[0]) == 255 || Number(rgb[0]) == 0)?230:Number(rgb[0]); 
    res.push({color: aux, opacity: opacity, colorB:[Number(rgb[1]),Number(rgb[2]),Number(rgb[3]), opacity255 ]});
  }
  return res;
}
/*
  Transform opacity to 0 to 1
*/
export function transformOpacity(value) {
  // return 1;
  return (value == 255 || value == 0) ? 0.9 : value / 255;
}
/*
  input:  color is in format rgba(255,255,255,0.2)
  tranforms it to an array to get the colors independent 
  and check the luminosity of the color
  output: white or black in hex
*/
export function getColor(color) {
  if (!color) {
    return '#000000';
  }
  /*let r:number = (i*8*4);
  return "rgb("+r+","+r+","+r+")";
*/
  // checks the luminosity of background
  const c = color.substring(5, color.length - 1);
  const vals = c.split(',');
  const r = parseInt(vals[0]);
  const g = parseInt(vals[1]);
  const b = parseInt(vals[2]);
  const op = parseFloat(vals[3]);
  if ((r * 0.299 + g * 0.587 + b * 0.114)  > 150) {
    return '#000000';
  } else {
    return '#ffffff';
  }

}