import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import DatasetDropdown from './DatasetDropdown';

import Filter from './Filter';
import RegionalRadio from './RegionalRadio';
import RatesCasesRadio from './RatesCasesRadio';
import YearDropdown from './YearDropdown';
import CitiesDropdown from './CitiesDropdown';

const RegionalFilter = ({options, arrowValue}) => {

  const [isCollapsed2, setIsCollapsed2] = useState( true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showYear, setShowYear] = useState(false);
  const [showCities, setShowCities] = useState(false);
  const [isCollapsed3d, setIsCollapsed3d] = useState(false);
  useEffect(()=>{
    setShowYear(options.hasYear);
  },[options.hasYear]);
  useEffect(()=> {
    setShowCities(options.maptype === 'city');
  },[options.maptype])
  useEffect(()=>{
    setIsCollapsed3d(arrowValue.isCollapsed);
    setIsCollapsed(arrowValue.isCollapsed);
  },[arrowValue]);
  return (
    <div className="complete-width table-style">
      <div className="navbar-group">
        <nav className="navbar navbar-primary">
          <div className={"navbar-collapsed-menu-desktop" + (isCollapsed ? ' d-show-flex' : ' d-hide')} >
            <span className="d-flex flex-column align-items-center " >
              <span className="filter-title" >MAP DATASETS</span>
              <span className="filter-arrow-red" >
                <img height="16px" className="p-0" src="assets/arrow-collapse-down.svg" alt="" />
              </span>
            </span>
          </div>
          <div className={"w-100 p-0" + (isCollapsed ? ' d-hide' : ' d-show-flex')}>
            <button className="navbar-toggler navbar-toggler-right table-hidden" type="button"
              onClick={() => setIsCollapsed2(!isCollapsed2)}>
              MAP DATA
            <span>
                <img height="12px" src="assets/arrow-collapse-down.svg" alt="" />
              </span>
            </button>
            {/* [ngClass]="{'d-show-flex': globals.isCollapsed2, 'd-hide': !globals.isCollapsed2}" */}
            <div className={"navbar-menu-top " + (isCollapsed2?'d-show-flex':'d-hide') }>
              <div className="navbar-mobile-title">
                <span className="title-primary-mobile">Select Map Data</span>
                {/* (click)="changeCollapse2();loadMapLayers()" */}
                <span style={{top:'15px'}} className="navbar-mobile-close" onClick={()=>setIsCollapsed2(!isCollapsed2)}><img height="13px" src="assets/close-mobile.svg" alt="" /></span>
              </div>
              <div className="navbar-collapse">
                <span className="nav-link nav-link-mobile-00">View data by:</span>
                <RegionalRadio />
                { showCities && <CitiesDropdown />}
              </div>
              <div className="navbar-collapse">
                <span className="nav-link margin-table nav-link-mobile-00 mm-rr" >Select a dataset:</span>
                {/* dropdown */}
                <DatasetDropdown />
                <RatesCasesRadio />
                { showYear && <YearDropdown />}
                {/* *ngIf="options && options.hasYear" */}
                {/* dropdown */}
                {/* <div className="btn-group"  >
                  dropdownToggle
                  <button id="button-animated"  type="button" className="btn btn-primary dropdown-toggle year-dropdown " aria-controls="dropdown-animated">
                    {{options.year.selected !='none'?options.year.selected:'Year'}}
                    <i className="mdi mdi-menu-down"></i>
                  </button>
                  *dropdownMenu
                  <ul id="dropdown-animated" className="dropdown-menu" role="menu" aria-labelledby="button-animated">
                     *ngFor="let year of getYears(options.year.year)"
                    (click)="options.year.selected=year; updateFromServer() "
                    <li role="menuitem"><a className="dropdown-item" >
                      {{year}}
                    </a> </li>
                  </ul>
                </div> */}
                {/* *ngIf="options && isMobile" */}
                {/* dropdown */}
                  {/* (click)="loadMapLayers();changeCollapse2()" */}
                {/* <div className="btn-group"  >
                  <button id="viewmap" className="btn btn-primary  button-view" >
                    VIEW MAP
                  </button>
                </div> */}
                <span className="toogler-button ml-4" ></span>
              </div>
            </div>
          </div>
        </nav>
        {/* <app-filters
    (updateOptionsEvent)="updateOptions($event)"
    [options]='options'
    [idRatescases]='countyratescases'
    [idSex]='countysex'
    [sexname]='sexname'
    (updateCollapseSP)="sendCollapseSP($event)"
    (emitArrow)="arrowCollapsed($event)"
    (resetOverlays)="resetServiceProviders()"
    (showMap)="loadMapLayers()"
    [areaSubject]="areaSubject"
    ></app-filters> */}

    {/*  *ngIf="!(options && options.city)" */}
        <Filter />
            {/* [ngClass]="{'d-show-flex': true}" */}
        {/* <nav className="navbar navbar-secondary">
          <div className="navbar-collapsed-menu-desktop" >
          </div>
        </nav> */}
      </div>
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    options: state.options,
    arrowValue: state.arrowValue
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeMaptype: (value) => {
      dispatch({ type: 'CHANGE_VALUE', key:'maptype', value });
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionalFilter)
