
const dev = {
  production: false,
  prefix: '',
  BASE_URL: '/api',//'http://3.14.105.20:8080'
  // BASE_URL: 'http://localhost:8080',
  name: 'test',
  table: 'table_',
  // carto_api_key: '1c3aacf0d05db9a2fe0bcb3a47e1bf599e240e42',
  // carto_account: 'aidsvu-admin',
  STATE_COMPARISON_TABLE: 'OpioidIndicators',
  STATE_COMPARISON_TABLE_YEARS: 'statetreatmentrestriction_20231213',
  carto_api_key: 'f119f68e530e2063958ca9eff617f6e70014213e',
  carto_account: 'signalgroup-aidsvu-staging'
};
const prod = {
  production: false,
  prefix: '',
  BASE_URL: '/api',
  // BASE_URL: 'http://3.14.105.20:8080',
  // BASE_URL: 'http://localhost:8080',
  name: 'test',
  table: 'table_',
  carto_api_key: '1c3aacf0d05db9a2fe0bcb3a47e1bf599e240e42',
  carto_account: 'aidsvu-admin',
  STATE_COMPARISON_TABLE: 'OpioidIndicators',
  STATE_COMPARISON_TABLE_YEARS: 'statetreatmentrestriction_20231213',
  // carto_api_key: 'f119f68e530e2063958ca9eff617f6e70014213e',
  // carto_account: 'signalgroup-aidsvu-staging'
};

export const environment = 
process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

console.log(process.env.REACT_APP_STAGE);
