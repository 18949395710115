import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Button, ButtonGroup } from 'reactstrap';

const RegionalRadio = ({options, changeMaptype}) => {

  const [radioSelected, setRadioSelected] = useState(null);

  const setMaptype = (value) => {
    setRadioSelected(value);
    changeMaptype(value)
  }

  useEffect(()=>{
    if(options.maptype != radioSelected) {
      setRadioSelected(options.maptype);
    }
  },[options.maptype])
  return (
    <ButtonGroup id="select-datatype" className="mobile-section">
      {/* <Button color="primary" onClick={() => setMaptype('city')} active={radioSelected === 'city'}>City</Button> */}
      <Button color="primary" onClick={() => setMaptype('county')} active={radioSelected === 'county'}>County</Button>
      <Button color="primary" onClick={() => setMaptype('state')} active={radioSelected === 'state'}>State</Button>
    </ButtonGroup>
  )
}

const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeMaptype: (value) => {
      dispatch({ type: 'CHANGE_VALUE', key:'maptype', value });
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionalRadio)
