
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { loadCities } from '../services/cityService';
import { defaultOptions } from './../defaultOptions';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { search } from './../services/dataComparisonService';
import { statesInScreen, searchState, citiesInScreen, searchCitiesOnStateProviders } from './../services/localStatisticsService';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import classNames from 'classnames';


const FloatingButtons = ({ options, zoomin, zoomout, bounds, zoom, isCollapsed, stateLocalStatisticsArray, updateState, comparisonOpt, hasParams, changeDCOption, selectedDCYear }) => {
  const [showReturnButton, setShowReturnButton] = useState(false);
  const [showViewCityMode, setShowViewCityMode] = useState(false);
  const [showLocalStatics, setShowLocalStatistics] = useState(false);
  const [cityLocalStatisticsArray, setCityLocalStatisticsArray] = useState([]);
  const [dropdownOpenStatistics, setDropdownOpenStatistics] = useState(false);
  const [dropdownOpenCities, setDropdownOpenCities] = useState(false);
  const [stateLocalStatisticsDropdown, setStateLocalStatisticsDropdown] = useState([]);
  const localStatisticsZoomLimit = 4.6;
  const [dropdownSelectYear, setDropdownSelectYear] = useState(false);
  const [isMobile, setIsMobile]  = useState(false);
  const [selectedYEARDC, setselectedYEARDC] = useState({year: 2017, change: 0});
  const searchCitiesOnState = (state) => {
    return searchCitiesOnStateProviders(state, cityLocalStatisticsArray, stateLocalStatisticsArray);
  }
  const loadCityMode = (city) => {
    loadCities().then((cities) => {
      let cityname = '';
      cities.forEach(({ city }) => {
        let mycity = city.replace(/ /g, '');
        mycity = mycity.toLowerCase();
        if (mycity.includes(city) || city.includes(mycity)) {
          cityname = city;
        }
      });
      let newOptions = defaultOptions;
      newOptions.maptype = 'city';
      newOptions.geographytype['selected'] = 'zipcode';
      newOptions.city = cityname;
      updateState(newOptions);
    })
  }
  useEffect(() => {
    setShowLocalStatistics(zoom >= localStatisticsZoomLimit);
    setShowViewCityMode(zoom >= localStatisticsZoomLimit);
    if (zoom >= localStatisticsZoomLimit) {
      citiesInScreen(bounds).then((d) => {
        let cities = d.data.rows;
        setCityLocalStatisticsArray(cities);
      })
      statesInScreen(bounds).then((d) => {
        let states = d.data.rows;
        let newStateDropdownValues = [];
        states.forEach(state => {
          state.name = state.name.replace(/\./g, '');
          const provider = searchState(stateLocalStatisticsArray, state.name);
          if (provider['state']) {
            newStateDropdownValues.push(provider);
          }
        });
        newStateDropdownValues.sort((a, b) => {
          return a.state.toLowerCase().localeCompare(b.state.toLowerCase());
        });
        setStateLocalStatisticsDropdown(newStateDropdownValues);
      });

    }

  }, [zoom]);
  const [showYearDC, setShowYearDC] = useState(false);
  const [listyearsDC, setlistyearsDC] = useState([2014,2015,2016,2017,2018,2019]);
  useEffect(()=>{
    let selectedDC = selectedDCYear;
    if(selectedDC) {
      changeDCOption('datacomparison',selectedDC+selectedYEARDC.year.toString().substring(2));
    } else {
      setShowYearDC(false);
    }
  },[selectedYEARDC]);
  useEffect(() => {
    if(options.latestValue === 'reset') {
      setShowYearDC(false);
    }
  },[options.latestValue]);
  useEffect(()=>{
    if(selectedDCYear) {
      let selectedDC = selectedDCYear;
      
      let booleanState = selectedDC.includes('narcoticoverdosemort') || selectedDC.includes('fibrosis') || selectedDC.includes('prescriber') || selectedDC.includes('sobriety') || selectedDC.includes('pastatus') || selectedDC.includes('retreatment');
      
      let booleanCounty = selectedDC.includes('narcoticoverdosemort');
      if( booleanState && options.maptype == 'state')  {
        setShowYearDC(true);
        if(selectedDC.includes('narcoticoverdosemort')) {
          setlistyearsDC([2014,2015,2016,2017,2018,2019,2020]);
          setselectedYEARDC({year:2020, change: Math.random()});
        } else if(selectedDC.includes('retreatment')) {
          setlistyearsDC([2022, 2023]);
          setselectedYEARDC({year:2023, change: Math.random()});
        } else {
          setlistyearsDC([2017,2018,2019,2020,2021,2022, 2023]);
          setselectedYEARDC({year:2023, change: Math.random()});
        }
      } else if (booleanCounty && options.maptype == 'county') {
        setShowYearDC(true);
        console.log('selecgeddc', selectedDC);
        if(selectedDC.includes('narcoticoverdosemort')) {
          setlistyearsDC([2014,2015,2016,2017,2018,2019,2020]);
          setselectedYEARDC({year:2020, change: Math.random()});
        } else if (selectedDC.includes('opioidprescription')) {
          setlistyearsDC([2018]);
          setselectedYEARDC({year:2018, change: Math.random()});
        } else {
          setlistyearsDC([2017,2018,2019,2020,2021]);
          setselectedYEARDC({year:2021, change: Math.random()});
        }
      }else {
        setShowYearDC(false);
      }
    } else {
      setShowYearDC(false);
    }
  },[selectedDCYear]);
  useEffect(()=>{
    const changeMobile  = window.innerWidth < 900 ? true: false;
    setIsMobile(changeMobile);
  },[]);
  const getDataComparisonTitle = () => {
    if (options.datacomparison['selected'] !== 'none' && options.datacomparison['selected'] !== '') {
      let value = search(options, comparisonOpt)[0];
      if(value) {
        return value.maptitle;
      } else {
        return '';
      }

    } else {
      return '';
    }
  }

  const showDropdownCity = (showViewCityMode && cityLocalStatisticsArray.length && options.maptype !== 'city' && options.datatype !== 'PrEP');

  return (
    <div className={"row no-gutters " + ((options.datacomparison.selected == 'none' || options.datacomparison.selected == '') ? 'row-postion1 ' : 'row-postion2 ')  + (isMobile?'title-mobile':'')} >
      { (options.datacomparison.selected !== 'none' && options.datacomparison.selected !== '') &&
        <div className={"header-map title-map-comparison " + (isCollapsed ? 'collapsed-titles' : '')} style={{ textAlign: 'center' }} id="header-mainmaptitle"  >
          <h6>{options.mainmaptitle}</h6>
        </div>
      }
      { (options.datacomparison.selected !== 'none' && options.datacomparison.selected !== '') &&
        <div className={"header-map title-map-comparison " + (isCollapsed ? 'collapsed-titles' : '')} style={{ textAlign: 'center', left: '50%' }} >
          <h6>{getDataComparisonTitle()}</h6>
        </div>
      }
      {
        showReturnButton &&
        <div className="col-xl-3 col-md-3 col-btn-next">
          <button type="button" className="btn btn-view" ><i className="mdi mdi-city"></i> Return </button>
        </div>
      }
      <div className={classNames({
          'map-buttons': true, 'text-right': true,
          'map-buttons-arrows': isCollapsed,
          // 'col-xl-6': showDropdownCity,
          // 'col-md-7': showDropdownCity,
          // 'col-xl-4': !showDropdownCity,
          // 'col-md-4': !showDropdownCity,
        })} >
        {
          !hasParams &&
          <div className="search-container geocoder" id="geocoder">
        </div>
        }
        {
          !hasParams &&
        <div className="btn-group btn-group-map" role="group" aria-label="Basic example">
          <button type="button" className="btn btn-map" style={{paddingTop: '4px'}} onClick={zoomin}><i className="mdi mdi-minus center" ></i></button>
          <button type="button" className="btn btn-map btn-line" style={{paddingTop: '4px'}}  onClick={zoomout}><i className="mdi mdi-plus center"></i></button>
        </div>
        }
        {
          showYearDC &&
          <div className="btn-year-map">
            <Dropdown isOpen={dropdownSelectYear} toggle={() => setDropdownSelectYear(!dropdownSelectYear)}>
              <DropdownToggle caret className="dropdown-toggle">
                {/* Select Comparison Year <i className="mdi mdi-menu-down"></i> */}
                {selectedYEARDC.year} <i className="mdi mdi-menu-down"></i> 
              </DropdownToggle>
              <DropdownMenu>
                {
                  listyearsDC.map((year) => {
                    if (options.datacomparison.selected.includes('retreatment22')) {
                      if (year >= 2022) {
                        return (
                          <a key={year} className="dropdown-item" onClick={() => setselectedYEARDC({year: year, change: Math.random()})}> {year}</a>
                        )
                      }
                    }else {
                      return (
                        <a key={year} className="dropdown-item" onClick={() => setselectedYEARDC({year: year, change: Math.random()})}> {year}</a>
                      )
                    }
                   })
                }
                
              </DropdownMenu>
            </Dropdown>
          </div>
        }
        {/* <div className="btn-year-map">
          <Dropdown isOpen={dropdownSelectYear} toggle={() => setDropdownSelectYear(!dropdownSelectYear)}>
            <DropdownToggle caret className="dropdown-toggle">
              Select Comparison Year <i className="mdi mdi-menu-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <a className="dropdown-item"> 2017</a>
              <a className="dropdown-item"> 2018</a>
              <a className="dropdown-item"> 2019</a>
              <a className="dropdown-item"> 2020</a>
              <a className="dropdown-item"> 2021</a>
            </DropdownMenu>
          </Dropdown>
        </div> */}
      </div>

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    options: state.options,
    selectedDCYear: state.dcoptions.selectedDCYear
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (options) => {
      dispatch({ type: 'REPLACE_OPTIONS_UPDATE', options })
    },
    changeDCOption: (filter,value) => {
      dispatch({ type: 'CHANGE_SELECTED', key:filter, value });
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FloatingButtons)
