import axios from 'axios';
import {SERVER, CARTO} from '../settings';
import {environment} from '../environments/environment';

const SERVER_URL = SERVER.BASE_URL;
let lastFilterQuery = null;
let filterCached = null;


export async function asyncGetCityFilters(query) {
  let areEqual = false;
  if (lastFilterQuery) {
    areEqual = JSON.stringify(lastFilterQuery) === JSON.stringify(query);
  }
  lastFilterQuery = JSON.parse(JSON.stringify(query));
  if (areEqual) {
    return filterCached;
  }

  if (typeof query.selected != "string") {
    query.selected = query.selected.sort().join('|');
  }

  let filterResponse = await axios.post(SERVER.CITY_FILTERS, query);
  let data = filterResponse.data?filterResponse.data:{};
      data.indicators = data.indicators?data.indicators:'';
      data.selected = data.selected?data.selected.split('|'):'';
      data.hidden = data.hidden?data.hidden.split('|'):'';
      data.autoselect = data.autoselect?data.autoselect.split('|'):'';
      data.unavailable = data.unavailable?data.unavailable.split('|'):'';
      data.available = data.available?data.available.split('|'):'';

  filterCached = data;
  // console.log("FILTER ", data);
  return filterCached;
}
export async function  getValuesOfCityTables(mainmaptable, mainmapvariable, mainmaplegendvariable, mainmaptable2, year, city) {
  if(city) {
    city = city.replace(/oakland\/alameda/g, 'oakland');
    if (!mainmaptable2 || mainmaptable2 === 'none') {
      let query;
      if (year !== 'hidden') {
        // tslint:disable-next-line:max-line-length
        query = CARTO.GET_QUERY(`SELECT t1.*, t1.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} t1 WHERE year=${year} AND  (LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%')`);
        query = encodeURI(query);
        if (query.includes('oakland&')) {
          query = query.replace('oakland&', 'oakland%26');
        }
      } else {
        query = CARTO.GET_QUERY(`SELECT t1.*, t1.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} t1 WHERE (LOWER(REPLACE(city, ', ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%')`);
        query = encodeURI(query);
      }
      let response = await axios.get(query);
      return response.data.rows;
    } else {
      let query;
      if (year !== 'hidden') {
        // tslint:disable-next-line:max-line-length
        query = CARTO.GET_QUERY(`SELECT mt.*, mt2.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.Cityname=mt2.Cityname and mt2.year=${year} and mt.year=mt2.year and (LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%')`);
        query = encodeURI(query);
      } else {
        // tslint:disable-next-line:max-line-length
        query = CARTO.GET_QUERY(`SELECT mt.*, mt2.${mainmaplegendvariable} as mainmaplegendvariable FROM ${environment.table}${mainmaptable} mt, ${environment.table}${mainmaptable2} mt2 WHERE mt.Cityname=mt2.Cityname and (LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%')`);
        query = encodeURI(query);
      }
      let response = await axios.get(query);
      return response.data.rows;
    }
  } else {
    return [];
  }
}

export async function getBoundingBox(geotype, city) {
  city = city.replace(/oakland\/alameda/g, 'oakland');
  let q = '';
  const centerx = 'ST_X(ST_Centroid(the_geom)) as centerx';
  const centery = 'ST_Y(ST_Centroid(the_geom)) as centery';
  const bboxx1 = 'ST_X(ST_StartPoint(ST_BoundingDiagonal(the_geom))) as bboxx1';
  const bboxy1 = 'ST_Y(ST_StartPoint(ST_BoundingDiagonal(the_geom))) as bboxy1';
  const bboxx2 = 'ST_X(ST_EndPoint(ST_BoundingDiagonal(the_geom))) as bboxx2';
  const bboxy2 = 'ST_Y(ST_EndPoint(ST_BoundingDiagonal(the_geom))) as bboxy2';
  const box = `${centerx}, ${centery}, ${bboxx1}, ${bboxy1}, ${bboxx2}, ${bboxy2}`;
  if (geotype === 'zipcode') {
    // tslint:disable-next-line:max-line-length
    q = `SELECT ${box}, the_geom FROM (SELECT ST_Collect(the_geom) as the_geom FROM ${CARTO.CITY_ZIP_CODE} WHERE LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%') as query`;
    if (city === 'jackson' || city === 'jacksonville') {
      let name;
      if (city === 'jackson') {
        name = 'Jackson';
      } else {
        name = 'Jacksonville';
      }
      q = `SELECT ${box}, the_geom FROM (SELECT ST_Collect(the_geom) as the_geom FROM ${CARTO.CITY_ZIP_CODE} WHERE city LIKE '${name}') as query`;
    }
    q = encodeURI(q);
  } else if (geotype === 'censustract') {
    q = `SELECT ${box}, the_geom FROM (SELECT ST_Collect(the_geom) as the_geom FROM  ${CARTO.CITY_CENSUS} WHERE LOWER(REPLACE(cityid, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(cityid, ' ', ''))||'%') as query`;
    q = encodeURI(q);
  } else if (geotype === 'communityarea') {
    q = `SELECT ${box}, the_geom FROM (SELECT ST_Collect(the_geom) as the_geom FROM ${CARTO.CITY_COMMUNITY}) as query`;
  } else if (geotype === 'ward') {
    q = `SELECT ${box}, the_geom FROM (SELECT ST_Collect(the_geom) as the_geom FROM ${CARTO.CITY_WARD}) as query`;
  }
  const query = CARTO.GET_QUERY(q);
  let response = await axios.get(query);
  return response.data.rows[0];
}

export async function cityLoader(data, options, bbox) {
  const mapconfig = {
    layers: [
      {
        type: "cartodb",
        options: { sql: 'SELECT * FROM ' + CARTO.STATE_TABLE }
      }
    ]
  }
  let city = options.city;
  city = city.replace(/oakland\/alameda/g, 'oakland');
  const geotype = options.geographytype['selected'];
  let q = '';
  if (geotype === 'zipcode') {
    // tslint:disable-next-line:max-line-length
    q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%'`;
    if (options.city === 'jackson') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE city LIKE 'Jackson'`;
    }
    if (options.city === 'jacksonville') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE city LIKE 'Jacksonville'`;
    }
    // q = encodeURI(q);
  } else if (geotype === 'censustract') {
    // tslint:disable-next-line:max-line-length
    q = `SELECT * FROM ${CARTO.CITY_CENSUS} WHERE LOWER(REPLACE(cityid, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(cityid, ' ', ''))||'%'`;
    if (options.city === 'Jackson') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE city LIKE 'Jackson'`;
    }
    if (options.city === 'Jacksonville') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE city LIKE 'Jacksonville'`;
    }
    // q = encodeURI(q);
  } else if (geotype === 'communityarea') {
    q = `SELECT * FROM ${CARTO.CITY_COMMUNITY}`;
  } else if (geotype === 'ward') {
    q = `SELECT * FROM ${CARTO.CITY_WARD}`;
  }
  mapconfig.layers[0].options['sql'] = q;
  return this.webService.post(`https://${CARTO.USERNAME}.carto.com/api/v1/map`, mapconfig).map(res => res.json().metadata.tilejson.vector.tiles);

}

export async function loadCities() {
  let response = await axios.get(SERVER.GET_CITIES);
  return response.data;
}
export async function getGeographytypeOptions(city) {
  // get from city permutation options by city
  let response = await axios.post(SERVER.CITY_GEOTYPE, { city: city });
  return response.data;
}

const search = (options, comparisonOptions) => {
  if(comparisonOptions) {
    const ans = comparisonOptions.filter(ele => {
      if (options.maptype === 'city') {
        // tslint:disable-next-line:max-line-length
        return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && options.geographytype['selected'] === ele.geotype;
      } else {
        return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison;
      }
    });
    return ans;
  } else {
    return [];
  }
  
}
export async function dataComparisonCity(options,comparisonOptions) {
  let city = options.city;
  city = city.replace(/oakland\/alameda/g, 'oakland');
  if (options.datacomparison.selected !== 'none') {
    let table = '';
    let zipcensus = '';
    if (options.geographytype['selected'] === 'zipcode') {
      table = CARTO.CITY_ZIP_COMPARER;
      zipcensus = 'zip';
    } else {
      table = CARTO.CITY_CENSUS_COMPARER;
      zipcensus = 'census';
    }
    const comparer = search(options, comparisonOptions)[0];
    if(comparer) {

      let query = CARTO.GET_QUERY(`SELECT ${comparer.mapvariable} as value, ${comparer.maplegendvariable} as legendPos, ${zipcensus} as zipcensus, city, year FROM ${table} WHERE LOWER ( REPLACE (city, ' ', '')) LIKE '%' ||LOWER('${city}') || '%' OR LOWER('${city}') LIKE '%'|| LOWER ( REPLACE(city, ' ', '')) || '%' `);
      query = encodeURI(query);
      let response = await axios.get(query).catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
      });
      return response.data.rows;
    } else {
      return [];
    }
    
  }
}
export function getGeoJsonCity (city, geotype) {
  city = city.replace(/oakland\/alameda/g, 'oakland');
  let q = '';
  if (geotype === 'zipcode') {
    // tslint:disable-next-line:max-line-length
    q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (LOWER(REPLACE(city, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(city, ' ', ''))||'%')`;
    if (city === 'jackson') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (city LIKE 'Jackson')`;
    }
    if (city === 'jacksonville') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (city LIKE 'Jacksonville')`;
    }
    // q = encodeURI(q);
  } else if (geotype === 'censustract') {
    // tslint:disable-next-line:max-line-length
    q = `SELECT * FROM ${CARTO.CITY_CENSUS} WHERE (LOWER(REPLACE(cityid, ' ', '')) LIKE '%'||LOWER('${city}')||'%' OR LOWER('${city}') LIKE '%'||LOWER(REPLACE(cityid, ' ', ''))||'%')`;
    if (city === 'Jackson') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (city LIKE 'Jackson')`;
    }
    if (city === 'Jacksonville') {
      q = `SELECT * FROM ${CARTO.CITY_ZIP_CODE} WHERE (city LIKE 'Jacksonville')`;
    }
    // q = encodeURI(q);
  }
  return q;
}

export function  getCityPopup(city) {
  return axios.post(SERVER.CITY_POPUPS, {city: city});
}