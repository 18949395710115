import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';

const TutorialPopup = ({ isOpen, setIsOpen }) => {

  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  };

  const nxt = () => {
    let n = parseInt(activeTab);
    if (n !== 10) {
      setActiveTab(`${n+1}`)
    } else {
      setIsOpen(false);
    }
  }

  const className = 'modal-tutorial';

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} size={'md'} style={{height: '410px'}}>
      <ModalBody className='modal-body'>
        <Row>
          <Col xs="6" sm="4" md="4">
            <Nav pills vertical>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggleTab('1'); }}
                >
                  NEW TO HepVu?
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggleTab('2'); }}
                >
                  MAP OVERVIEW
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggleTab('3'); }}
                >
                  TITLE & LEGEND
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => { toggleTab('4'); }}
                >
                  GEOGRAPHY
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => { toggleTab('5'); }}
                >
                  DATASET
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '6' })}
                  onClick={() => { toggleTab('6'); }}
                >
                  RATE / CASE COUNT
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '7' })}
                  onClick={() => { toggleTab('7'); }}
                >
                  DEMOGRAPHIC FILTERS
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '9' })}
                  onClick={() => { toggleTab('9'); }}
                >
                  DATA COMPARISON
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({ active: activeTab === '10' })}
                  onClick={() => { toggleTab('10'); }}
                >
                  ADDITIONAL TOOLS
                </NavLink>
              </li>
            </Nav>
          </Col>
          <Col xs="6" sm="8" md="8">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                  <h2>New to HepVu?</h2>
              Our 30 second tutorial will teach you how to use the map.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                <Col sm="12">
                <h2>Map Overview</h2>
                    <div>
                    Search for your location or use the mouse scroller or trackpad to zoom in and out. You can also press the +/- buttons to zoom or use the mouse to click and drag the location you are viewing on the map.
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                  <h2>Title & Legend</h2>
                  Each map has a title at the bottom of the map, as well as a legend that shows the map's color key and corresponding range.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                  <h2>Geography</h2>
                  Click County or State to view data at different geographic levels.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col sm="12">
                  <h2>Dataset</h2>
                  Select the data you would like to view in the top right corner to see different hepatitis-related data types, including prevalence of people currently living with Hepatitis C, Hepatitis C mortality, and opioid prescription usage, pain reliever misuse, and narcotic mortality. 
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col sm="12">
                  <h2>Rate/Case Count</h2>
                  The maps can be viewed by rates or cases. The rates maps show the number of cases per 100,000 people in the population to view the relative concentration. However, the opioid prescription rates map shows the number of cases per 100 people.  
                    <br></br>  
                    <br></br>  
                    This differs from the cases maps, which show the actual number of cases.  
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="7">
                <Row>
                  <Col sm="12">
                  <h2>Demographic Filters</h2>
                  View the maps by age, sex, or race/ethnicity by selecting those options in the black filter bar. If a filter is shaded, it is unavailable for the map view.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="9">
                <Row>
                  <Col sm="12">
                  <h2>Data Comparisons</h2>
                  You can also view HepVu maps next to maps of social determinants of health, like income inequality or educational attainment.
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="10">
                <Row>
                  <Col sm="12">
                  <h2>Additional Tools</h2>
            
                  Browse options in the bottom grey bar to download the data behind the maps, download an image of the map you’re viewing, share or imbed the map, or find additional information, including the data methods.
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
          <Button className='btn-modal' color="primary" onClick={nxt}>Continue</Button>
          </Col>
          <Col md={8} className='modal-logo'>
            <img _ngcontent-c0="" alt="" className="logo-modal panel-1" src="/assets/logo.svg" />
          </Col>
        </Row>
      </ModalBody>
      {/* <ModalFooter>
      </ModalFooter> */}
    </Modal>
  )
}

export default TutorialPopup;