import axios from 'axios';
import {SERVER, CARTO} from '../settings';
import {environment} from '../environments/environment';

const SERVER_URL = SERVER.BASE_URL;
let lastFilterQuery = null;
let filterCached = null;
let comparisonOpt = null;
export async function loadComparisonOptions() {
  let response = await axios.get(SERVER.GET_DC);
  comparisonOpt = response.data;

  return response.data;
}
export function search(options,comparisonOptions) {
  const ans = comparisonOptions.filter(ele => {
    if (options.maptype === 'city') {
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && options.geographytype['selected'] === ele.geotype;
    } else {
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison;
    }
  });
  return ans;
}
export function searchRegular(options) {
  const ans = comparisonOpt.filter(ele => {
    if (options.maptype === 'city') {
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison && options.geographytype['selected'] === ele.geotype;
    } else {
      return options.maptype === ele.datatype && options.datacomparison['selected'] === ele.datacomparison;
    }
  });
  return ans;
}