import axios from 'axios';
import {SERVER, CARTO} from '../settings';
import {environment} from '../environments/environment';

const SERVER_URL = SERVER.BASE_URL;


export async function countyLoader() {
  const query = CARTO.GET_QUERY('SELECT geo_id2, name, state FROM ' + CARTO.COUNTY_TABLE2);
  let response = await axios.get(query);
  return response.data.rows;

}
export async function wardLoader() {
  const query = CARTO.GET_QUERY('SELECT cartodb_id, ward FROM ' + CARTO.CITY_WARD);
  let response = await axios.get(query);
  return response.data.rows;

}
export async function communityLoader() {
  const query = CARTO.GET_QUERY('SELECT cartodb_id, community FROM ' + CARTO.CITY_COMMUNITY);
  let response = await axios.get(query);
  return response.data.rows;
}
// export async function getAuthHeadersWithoutContentType(token?: string) {
//   const options = new RequestOptions({ });
//   return options;
// }
// export async function getFileCsv(type): Observable<Blob> {
//   return this.httpClient.get(`${SERVER.GET_CSV}/${type}`, { responseType: 'blob' });
// }